<div id="autorizacionesEnviadasListado" class="dtx-tabla-dinamica">
  <div class="container__encabezado text-center">
    <div class="col-12">
      <h3>{{'navbar.autorizaciones-enviadas' | translate}}</h3>
    </div>
  </div>
  <div class="listado-autorizaciones">
    <p-table #autorizacionesTabla [responsive]="true" [value]="listadoAutorizaciones" dataKey="Id" [rows]="10"
      [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,20,30]" styleClass="p-datatable-autorizaciones"
      [paginator]="true" [currentPageReportTemplate]="reportePaginaActualPaginador"
      [globalFilterFields]="['FechaSolicitud','TipoProducto','Origen','Destino','FechaInicio','FechaRespuesta','Precio', 'PrecioLimiteAutorizado']">
      <ng-template pTemplate="caption">
        <div class="filtro-flex">
          <div class="busqueda-tabla"></div>
          <span class="p-input-icon-left p-ml-auto">
            <i class="pi pi-search"></i>
            <input class="p-inputtext" pInputText type="text"
              (input)="autorizacionesTabla.filterGlobal($event.target.value, 'contains')" [(ngModel)]="textoFiltro"
              placeholder="{{ 'admin.comun.busqueda' | translate }}" />
          </span>
        </div>
        <!-- BUSCADOR RANGO FECHAS -->
        <div style="padding-top:1em">
          <elegir-rango-fechas
                textoExplicativo="{{'autorizaciones-viajes.listado-autorizaciones.rango-fechas.texto-informativo-limites' | translate}}"
                (onRangeSelection)="hndConsultarPorRangoFechas($event)"
                (onLimpiarSeleccion)="hndLimpiarSeleccionCalendario()" (onIrAHoy)="hndIrAHoy()"></elegir-rango-fechas>
        </div>
      </ng-template>
      <!--HEADERS-->
      <ng-template pTemplate="header">
        <tr>
          <th style="width: 1.5rem"></th>
          <th pSortableColumn="FechaSolicitud">{{'autorizaciones-viajes.listado-autorizaciones.header.fecha-solicitud' |
            translate}}<p-sortIcon field="FechaSolicitud"></p-sortIcon>
            <p-columnFilter type="date" field="FechaSolicitud" display="menu" [showMatchModes]="true">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-calendar #calendar [ngModel]="value" (onSelect)="filter(calendar.value)" dateFormat="dd/mm/yy">
                </p-calendar>
              </ng-template>
            </p-columnFilter>
          </th>
          <th style="width: 4rem;">
            <div>{{'autorizaciones-viajes.listado-autorizaciones.header.tipo' | translate}}</div>
            <div>
              <p-columnFilter field="TipoProducto" matchMode="in" [showMenu]="false">
                <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                  <p-multiSelect [ngModel]="value" [options]="tiposDeProducto" [placeholder]="placeholderSelect"
                    (onChange)="filter($event.value)" optionLabel="name" optionValue="code">
                    <ng-template let-option pTemplate="item">
                      <div>
                        <span class="text icono-producto"><i [ngClass]="getIconoProducto(option.code, option.esApartamento)"></i></span>
                      </div>
                    </ng-template>
                  </p-multiSelect>
                </ng-template>
              </p-columnFilter>
            </div>
          </th>

          <th pSortableColumn="Origen">{{'autorizaciones-viajes.listado-autorizaciones.header.origen' | translate}}
            <p-sortIcon field="Origen"></p-sortIcon>
          </th>
          <th pSortableColumn="Destino">{{'autorizaciones-viajes.listado-autorizaciones.header.destino' | translate}}
            <p-sortIcon field="Destino"></p-sortIcon>
          </th>
          <th pSortableColumn="FechaInicio">{{'autorizaciones-viajes.listado-autorizaciones.header.inicio-ida' |
            translate}}<p-sortIcon field="FechaInicio"></p-sortIcon>
            <p-columnFilter type="date" field="FechaInicio" display="menu"></p-columnFilter>
          </th>
          <th pSortableColumn="FechaFin">{{'autorizaciones-viajes.listado-autorizaciones.header.fin-vuelta' |
            translate}}<p-sortIcon field="FechaFin"></p-sortIcon>
            <p-columnFilter type="date" field="FechaFin" display="menu"></p-columnFilter>
          </th>
          <!-- Mostrar el precio límite autorizado haya o no respuesta -->
          <th pSortableColumn="PrecioLimiteAutorizado" style="width: 10%;">
            {{'autorizaciones-viajes.listado-autorizaciones.header.precio-limite' |
            translate}}<p-sortIcon field="PrecioLimiteAutorizado"></p-sortIcon>
            <p-columnFilter type="date" field="PrecioLimiteAutorizado" display="menu"></p-columnFilter>
          </th>
          <th>{{'autorizaciones-viajes.listado-autorizaciones.header.estado' | translate}}
            <p-columnFilter field="_Estado" matchMode="equals" [showMenu]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-dropdown [ngModel]="value" [options]="estadosCalculados" (onChange)="filter($event.value)"
                  [placeholder]="placeholderSelect" [showClear]="true" optionLabel="name" optionValue="code">
                  <ng-template let-option pTemplate="item">
                    <p-tag value="{{getTextoEtiquetaEstadoCalculado(option.code)}}"
                      [severity]="getColorEstadoCalculadoParaTag(option.code)"></p-tag>
                  </ng-template>
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
          </th>
          <th style="width: 5rem">{{'autorizaciones-viajes.listado-autorizaciones.header.acciones' | translate}}</th>
        </tr>
      </ng-template>
      <!--BODY-->
      <ng-template pTemplate="body" let-autorizacion let-expanded="expanded">
        <tr>
          <td>
            <button type="button" pButton [pRowToggler]="autorizacion"
              class="p-button-primary pax-button">
              <div>
                <span>{{autorizacion?.Viajeros?.length}}&nbsp;x&nbsp;</span><i class="pi pi-user inline"></i>
              </div>              
            </button>
          </td>
          <td class="date">
            <span class="p-column-title">{{'autorizaciones-viajes.listado-autorizaciones.header.fecha-solicitud' |
              translate}}</span>{{autorizacion.FechaSolicitud | date: 'dd/MM/yyyy HH:mm' }}
          </td>
          <td style="text-align: center;">
            <span class="p-column-title">{{'autorizaciones-viajes.listado-autorizaciones.header.tipo' | translate}}</span>
            <span class="text icono-producto"><ng-container *ngIf="autorizacion.TipoProducto == tipoProducto.Hotel">{{autorizacion.Cantidad}}x&nbsp;</ng-container>
              <i [ngClass]="getIconoProducto(autorizacion.TipoProducto, autorizacion.DatosComplementarios?.EsApartamento)">
                &nbsp;
                <span *ngIf="autorizacion.DatosComplementarios?.EsApartamento && autorizacion.TipoProducto == tipoProducto.Hotel">A</span>
                <span *ngIf="!autorizacion.DatosComplementarios?.EsApartamento && autorizacion.TipoProducto == tipoProducto.Hotel">H</span>
              </i>
            </span>
          </td>
          <td>
            <span class="p-column-title">{{'autorizaciones-viajes.listado-autorizaciones.header.origen' |
              translate}}</span><span class="text">{{autorizacion.Origen || '-'}}</span>
          </td>
          <td>
            <span class="p-column-title">{{'autorizaciones-viajes.listado-autorizaciones.header.destino' |
              translate}}</span><span class="text">{{autorizacion.Destino || '-'}} </span>
          </td>
          <td>
            <span class="p-column-title">{{'autorizaciones-viajes.listado-autorizaciones.header.inicio-ida' |
              translate}}</span>
            <span *ngIf="autorizacion?.TipoProducto != tipoProducto.Rentacar" class="date">{{autorizacion.FechaInicio |
              date:
              'dd/MM/yyyy'}}
            </span>
            <span *ngIf="autorizacion?.TipoProducto == tipoProducto.Rentacar" class="date">{{autorizacion.FechaInicio |
              date:
              'dd/MM/yyyy HH:mm'}}
            </span>
          </td>
          <td>
            <span class="p-column-title">{{'autorizaciones-viajes.listado-autorizaciones.header.fin-vuelta' |
              translate}}</span>
            <span *ngIf="autorizacion?.TipoProducto != tipoProducto.Rentacar" class="date">{{(autorizacion.FechaFin |
              date:
              'dd/MM/yyyy') || "-"}}
            </span>
            <span *ngIf="autorizacion?.TipoProducto == tipoProducto.Rentacar" class="date">{{(autorizacion.FechaFin |
              date:
              'dd/MM/yyyy HH:mm') ||  "-"}}
            </span>
          </td>
          <!-- Mostrar el precio límite autorizado haya o no respuesta -->
          <td style="text-align:end;font-size:1.25rem;font-weight: 600;">
            <span class="p-column-title">{{'autorizaciones-viajes.listado-autorizaciones.header.precio-limite' |
              translate}}</span>
            <ng-container *ngIf="autorizacion?.PrecioLimiteAutorizado">
              <span *ngIf="autorizacion?._radioKm" style="margin-right:0.25rem">
                <i [pTooltip]="getTooltipTextRadioKm(autorizacion?._radioKm)" class="fas fa-info-circle">
                </i>
              </span>
              <app-precio [precio]="autorizacion?.PrecioLimiteAutorizado"></app-precio>
            </ng-container>
          </td>
          <td style="text-align: center;">
            <span class="p-column-title">{{'autorizaciones-viajes.listado-autorizaciones.header.estado' |
              translate}}</span>
            <p-tag *ngIf="getTextoEtiquetaEstadoCalculado(autorizacion._Estado) != ''"
              value="{{getTextoEtiquetaEstadoCalculado(autorizacion._Estado)}}"
              [severity]="getColorEstadoCalculadoParaTag(autorizacion._Estado)"
              (click)="hndTagEstadoClick(autorizacion)">
            </p-tag>
          </td>
          <td style="text-align: center;">
            <span class="p-column-title">{{'autorizaciones-viajes.listado-autorizaciones.header.acciones' |
              translate}}</span>
            <i *ngIf="(autorizacion['_Estado'] == enumEstados.PendienteAprobacion)"
              class="pi cursor-pointer false-icon pi-times-circle"
              pTooltip="{{'mis-presupuestos.comun-productos.modal-confirmar.boton.eliminar' | translate}}"
              tooltipPosition="top" (click)="hndAccionClick(autorizacion, true)"></i>
          </td>
        </tr>
      </ng-template>
      <!-- ROW EXPANSION CONTENT -->
      <ng-template pTemplate="rowexpansion" let-autorizacion>
        <tr>
          <td colspan="10">
            <div class="p-p-3 borde-subtabla">
              <p-table [value]="autorizacion.Viajeros" [responsive]="true">
                <ng-template pTemplate="caption">{{'autorizaciones-viajes.detalle-solicitud.header.viajeros' |
                  translate}}</ng-template>
                <ng-template pTemplate="header">
        <tr>
          <th>{{'autorizaciones-viajes.listado-autorizaciones.header.nombre' | translate}}</th>
          <th>{{'autorizaciones-viajes.detalle-solicitud.header.email' | translate}}</th>
          <th>{{'autorizaciones-viajes.detalle-solicitud.header.empresa' | translate}}</th>
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-rowIndex="rowIndex" let-viajero>
        <tr>
          <td><span class="p-column-title">{{'autorizaciones-viajes.listado-autorizaciones.header.nombre' |
              translate}}</span>{{viajero.RamificacionConDatos[0]?.Nombre}}</td>
          <td><span class="p-column-title">{{'autorizaciones-viajes.detalle-solicitud.header.email' |
              translate}}</span>{{viajero.Email}}</td>
          <td><span class="p-column-title">{{'autorizaciones-viajes.detalle-solicitud.header.empresa' |
            translate}}</span>{{getNombreEmpresaConJerarquia(viajero.RamificacionConDatos)}}</td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  </td>
  </tr>
  <tr *ngIf="autorizacion.TipoProducto == tipoProducto.Hotel && false">
    <td colspan="10">
      <div>
        <span class="header__sub-seccion">{{'auto-reserva.hoteles.disponibilidad.num-habitaciones' |
          translate}}:&nbsp;</span>
        <span>{{autorizacion.Cantidad}}</span>&nbsp;
      </div>
      <div>
        <span
          class="header__sub-seccion">{{'autorizaciones-viajes.listado-autorizaciones.header.viajeros-por-habitacion' |
          translate}}:&nbsp;</span>
        <span>{{autorizacion.Viajeros?.length / autorizacion.Cantidad}}</span>
      </div>
    </td>
  </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <tr>
      <td colspan="10">{{'autorizaciones-viajes.listado-autorizaciones.texto-buscador.no-solicitudes' | translate}}
      </td>
    </tr>
  </ng-template>
  </p-table>
</div>
</div>
<p-toast></p-toast>