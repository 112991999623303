import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BotonAyudaComponent } from './boton-ayuda.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [BotonAyudaComponent],
  imports: [CommonModule, SweetAlert2Module.forRoot(), TranslateModule, MatButtonModule],
  exports: [BotonAyudaComponent]
})
export class BotonAyudaModule {}
