<div class="sidebar"
  [ngClass]="{ 'sidebar--expandida': propsSidebar.fijada, 'sidebar--abierta': propsSidebar.mostrar }">
  <div class="sidebar__logo" [class.sidebar__logo--expandida]="expandida">
    <div>
      <img [ngClass]="expandida ? 'logo--activo' : 'logo--inactivo'" class="logo logo--texto"
        src="assets/img/Logo_destinux_fondo_blanco.svg" alt="Logo Destinux" />
      <img [ngClass]="expandida ? 'logo--inactivo' : 'logo--activo'" class="logo logo--icono"
        src="assets/img/logo_destinux_icono.svg" alt="Logo Destinux" />
    </div>
    <!-- <div
      [style.display]="expandida ? 'inline' : 'none'"

      pTooltip="{{ (fijada ? 'sidebar.pin.contraer' : 'sidebar.pin.fijar') | translate }}"
      tooltipPosition="bottom"
    >
      <i class="fas fa-thumbtack pin" [style.color]="fijada ? 'black' : '#7d7d7d'"></i>
    </div> -->
  </div>
  <div class="sidebar__contraer" (click)="setFijado()">
    <span style="display: flex" [style.padding-left]="expandida ? '1em' : '0'"
      [style.justify-content]="expandida ? 'normal' : 'center'">
      <i class="fas fa-angle-double-right" *ngIf="!expandida; else barra_expandida"></i>
      <ng-template #barra_expandida>
        <i class="fas fa-angle-double-left"></i>
        <span style="font-size: 14px; padding-left: .5em;">
          {{ 'sidebar.pin.contraer' | translate }}
        </span>
      </ng-template>
    </span>
  </div>
  <div class="sidebar__menu" [class.sidebar__menu--contraida]="!expandida">
    <ul>
      <li class="sidebar__bloque" [class.sidebar__bloque--activo]="menuActivo == 'dashboard' && !expandida">
        <a [routerLink]="'/'" class="menu__item" routerLinkActive="menu__item--seleccionado"
          [routerLinkActiveOptions]="{ exact: true }">
          <span *ngIf="expandida">
            {{ 'sidebar.menu.dashboard' | translate }}
          </span>
          <i class="fas fa-home"></i>
        </a>
      </li>
      <li class="sidebar__bloque" [class.sidebar__bloque--activo]="menuActivo == 'travel-manager' && !expandida">
        <a [routerLink]="'/travel-management'" class="menu__item" routerLinkActive="menu__item--seleccionado"
          [routerLinkActiveOptions]="{ exact: true }">
          <span *ngIf="expandida">
            {{ 'sidebar.menu.travel-manager' | translate }}
          </span>
          <i class="fa fa-suitcase" aria-hidden="true"></i>
        </a>
      </li>
      <li class="sidebar__bloque" [class.sidebar__bloque--activo]="menuActivo == 'compensacion-co2' && !expandida">
        <a [routerLink]="'/herramientas/compensacionco2'" class="menu__item" routerLinkActive="menu__item--seleccionado"
          [routerLinkActiveOptions]="{ exact: true }">
          <span *ngIf="expandida">
            {{ 'sidebar.menu.compensacion-co2' | translate}}
          </span>
          <i class="demo-icon icon-icono-navbar-destinux-green"></i>
          <!-- <img src="../../../assets/img/compensacionco2/icono-navbar-destinux-green.svg" alt="" srcset="">
          <i class="fas fa-tree" aria-hidden="true"></i> -->
        </a>
      </li>
      <ng-container *ngFor="let item of itemsMenu; trackBy: trackBy">
        <li class="sidebar__bloque" *ngIf="!(item.deshabilitado && item.tipo == 'creacion')"
          [class.sidebar__bloque--deshabilitado]="item.deshabilitado"
          [class.sidebar__bloque--activo]="menuActivo == item.tipo && !expandida">
          <ng-container>
            <a class="menu__item submenu__titulo" (click)="item.submenuVisible = !item.submenuVisible"
              *ngIf="expandida">
              <ng-container *ngIf="expandida">
                <span>
                  {{ item.titulo | translate }}
                </span>
                <i class="fas fa-angle-down tick" [class.tick--active]="item.submenuVisible"></i>
              </ng-container>
            </a>
            <ul class="menu__submenu" *ngIf="item.submenuVisible && expandida">
              <ng-container *ngFor="let subItem of item.hijos; trackBy: trackBy">
                <!-- TODO: quitar ng-container con ngIf cuando se publique definitivamente -->
                <!-- <ng-container *ngIf="subItem.tipo == 'misPresupuestos' ? environment.demo ? true : false : true"> -->
                <li [class.submenu__item--deshabilitado]="subItem.deshabilitado">
                  <a [routerLink]="subItem.ruta" class="menu__item submenu__item"
                    routerLinkActive="menu__item--seleccionado">
                    <span>
                      {{ subItem.titulo | translate }}
                    </span>
                    <i [class]="subItem.icono"></i>
                  </a>
                </li>
                <!-- </ng-container> -->
              </ng-container>
            </ul>

            <ng-template #popContent>
              <a class=" enlaceLateral" *ngFor="let subItem of item.hijos; trackBy: trackBy" [routerLink]="subItem.ruta"
                [routerLinkActive]="'enlaceLateral--activo'"
                [class.enlaceLateral--deshabilitado]="subItem.deshabilitado">
                {{ subItem.titulo | translate }}
              </a>
            </ng-template>
            <a class="menu__item" [ngbPopover]="popContent" popoverClass="popover-enlaces bold" container="body"
              placement="right" *ngIf="!expandida">
              <i [class]="item.icono"></i>
            </a>
          </ng-container>
        </li>
      </ng-container>
    </ul>
  </div>
</div>
