import { PipeTransform, Pipe } from '@angular/core';
import * as dayjs from 'dayjs';


@Pipe({
  name: 'diasEntreFechas'
})
export class DiasEntreFechasPipe implements PipeTransform {

  constructor() { }

  transform(value: string): number {
    let resultado: number = 0;
    const arrayFechas = value.split("%");
    if (arrayFechas.length == 2) {

      const fechas = {
        inicio: dayjs(arrayFechas[0]),
        fin: dayjs(arrayFechas[1])
      };
      resultado = fechas.fin.diff(fechas.inicio, 'days');

    }
    return resultado;
  }
}