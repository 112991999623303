import { PipeTransform, Pipe } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'horasMinutos'
})
export class HorasMinutosPipe implements PipeTransform {

  constructor(private translate: TranslateService){}

  transform(value: number): string {
    let cadenaDevolver: string;
    if (value >= 60) {
      if (Math.trunc(value / 60) > 1) {
        cadenaDevolver = `${Math.trunc(value / 60)} ${this.translate.instant('rentacar.disponibilidad.horas')} `
      } else {
        cadenaDevolver = `${Math.trunc(value / 60)} ${this.translate.instant('rentacar.disponibilidad.hora')} `;
      }
      if (value % 60 != 0) {
        cadenaDevolver += ` ${this.translate.instant('rentacar.disponibilidad.y')} ${(value % 60)} ${this.translate.instant('rentacar.disponibilidad.minutos')} `
      }
    } else {
      cadenaDevolver = `${value} ${this.translate.instant('rentacar.disponibilidad.minutos')} `;
    }
    return cadenaDevolver;
  }
}
