import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { InfoAutorizador } from 'src/app/autorreserva/autorreserva-compartido/modelos';

@Component({
  selector: 'app-elegir-autorizador-dialog',
  templateUrl: './elegir-autorizador-dialog.component.html',
  styleUrls: ['./elegir-autorizador-dialog.component.scss']
})
export class ElegirAutorizadorDialogComponent implements OnInit {
  opcionesSeleccionadas:any[]=[];
  autorizadores:InfoAutorizador[]=[];
  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
  ) { 
  }

  ngOnInit(): void {
    this.autorizadores = this.config.data?.opciones;
    // this.autorizadores = []; // CHECK: descomentar para probar empresa usa autorizadores pero usuario no tiene asignados.
  }

hndCrearBtnClick():void{
  this.ref.close(this.opcionesSeleccionadas);
}

hndCancelarBtnClick(): void {
  this.ref.close(false);
}

}
