<button class="button boton-primario-hollow boton-ayuda" [swal]="modalAyuda">
  <i class="fas fa-info-circle boton-ayuda__icono"></i>
  <span class="boton-ayuda__texto">{{ 'navbar.ayuda' | translate }}</span>
</button>
<swal
  #modalAyuda
  [customClass]="{ popup: 'modal-ayuda' }"
  title="{{ 'modal-ayuda.titulo' | translate }}"
  icon="question"
  [allowOutsideClick]="true"
  [showCloseButton]="true"
  [showConfirmButton]="false"
  width="50rem"
  style="margin-top: 6em;"
>
  <div *swalPortal style="text-align: left;">
    <ul>
      <li>
        <span>
          {{ 'modal-ayuda.opcion-1' | translate }}:
          <a href="mailto:{{ usuario.CorreoGestor }}">{{ usuario.CorreoGestor }}</a>
        </span>
      </li>
      <li>
        <span> {{ 'modal-ayuda.opcion-2' | translate }} </span>
      </li>
    </ul>
  </div>
</swal>
