import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElegirRangoFechasComponent } from './elegir-rango-fechas.component';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [
    ElegirRangoFechasComponent
  ],
  imports: [
    CommonModule,
    CalendarModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule
  ],
  exports:[ElegirRangoFechasComponent]
})
export class ElegirRangoFechasModule { }
