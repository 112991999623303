import { HttpBackend, HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Funciones } from 'src/assets/funcionesComunes';
import { LISTADO_AUTORIZACIONES } from 'src/assets/misc/respuestas_API/autoreserva/autorizaciones-viajes/respuesta_autorizaciones_viajes';
import { LISTADO_SOLICITUDES_PENDIENTES } from 'src/assets/misc/respuestas_API/autoreserva/autorizaciones-viajes/respuesta_autorizaciones_viajes_solicitudes_pendientes';
import { environment } from 'src/environments/environment';
import { HelperFunctions } from '../autorizaciones-viajes/autorizaciones-viajes-helper-functions';
import { ItemUltimosRegistros } from '../autorreserva/autorreserva-compartido/lista-peticiones-disponibilidad/Modelos';
import {
  RespuestaSolicitudValores,
  IApiRespuesta,
  PetActualizarSolicitud,
  PetBorrarSolicitud,
  SolicitudAutorizacionModel,
  TipoProductoEtiquetaTraducidaValores,
  TipoProductoValores,
  InfoAutorizador,
  PetConsultarSolicitudes,
  EstadoCalculadoValores,
  EstadoCalculadoEtiquetaTraducidaValores,
  TipoViaje,
  PuntoViaje
} from '../autorreserva/autorreserva-compartido/modelos';
import { IHotel } from '../autorreserva/hoteles/modelos';
import { PeticionDisponibilidadRentacar } from '../autorreserva/rentacar/modelos/peticion-disponibilidad-rentacar.model';
import { PeticionDisponibilidad } from '../autorreserva/trenes/modelos/PeticionDisponibilidadTrenes';
import { PeticionDisponibilidadVuelos } from '../autorreserva/vuelos/modelos/PeticionDisponibilidadVuelos';
import { LoginService } from './login.service';
import { IEdificio } from '../autorreserva/apartamentos/modelos';


@Injectable({
  providedIn: 'root'
})
export class AutorizacionesViajesService implements OnDestroy {

  plataformaAPI$: BehaviorSubject<any>;

  listadoSolicitudesAutorizacion$: BehaviorSubject<any[]>;
  listadoSolicitudesAutorizacionAutorizador$: BehaviorSubject<any[]>;
  public loading$ = new BehaviorSubject<boolean>(true);
  info_user: any;
  fake_promise = new Promise((resolve, reject) => { console.log("I'm a fake promise for Demo"); resolve(true); });
  // PRUEBAS API LOCAL
  // generalURL: string = 'http://localhost:59094';
  private _solicitudSeleccionada = new BehaviorSubject<any>(null);
  solicitudSeleccionada$ = this._solicitudSeleccionada.asObservable();
  // PRUEBAS API LOCAL
  // headers:HttpHeaders;
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _httpService: HttpClient,
    private _translateService: TranslateService,
    private _loginService: LoginService,
    private _routerService: Router,
    // private handler: HttpBackend // PRUEBAS API LOCAL
  ) {  // CHECK: handler descomentado solamente para pruebas api local
    // this._httpService = new HttpClient(this.handler);
    this._unsubscribeAll = new Subject<any>();
    this.plataformaAPI$ = new BehaviorSubject<any>(null);
    this.listadoSolicitudesAutorizacion$ = new BehaviorSubject([]);
    this.listadoSolicitudesAutorizacionAutorizador$ = new BehaviorSubject([]);
    this._loginService.getLoggedIn().pipe(takeUntil(this._unsubscribeAll)).subscribe((loggedIn) => {
      if (!loggedIn) {
        this.listadoSolicitudesAutorizacion$.next([]);
        this.listadoSolicitudesAutorizacionAutorizador$.next([]);
        this._solicitudSeleccionada.next(null);
      } else {
        this.info_user = JSON.parse(localStorage.getItem('info_user'));
        // CHECK: headers descomentado solamente para pruebas api local
        /* this.headers = new HttpHeaders({
          IDSesion: localStorage.getItem('idsesion'),
          Authorization: `Bearer ${localStorage.getItem('user_token')}`
        }); */
        this.getSolicitudesAutorizacion();
        const esAutorizador = this.comprobarUsuarioEsAutorizador();
        if (esAutorizador || environment.demo) this.getSolicitudesAutorizacionAutorizador();
      }
    });
    this.loading$.next(true);
  }
  ngOnDestroy(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  comprobarUsuarioEsAutorizador(): boolean {
    const user = JSON.parse(localStorage.getItem('info_user'));
    return user?.Autorizador;
  }

  setValorLoading(valor: boolean): void {
    this.loading$.next(valor);
  }

  // OBTENER LISTADO AUTORIZACIONES DEL USUARIO 
  // (Estado distinto de Utilizada(0 | 1) )
  getSolicitudesAutorizacion(rangoFechas = null): Promise<any> {
    const datosPeticion = new PetConsultarSolicitudes({ FechaDesde: rangoFechas ? rangoFechas[0] : null, FechaHasta: rangoFechas ? rangoFechas[1] : null });
    let url = "";
    if (environment.pruebas || environment.demo) {
      const listadoFake1: any[] = LISTADO_AUTORIZACIONES;
      this.calcularNumeroViajerosParaListado(listadoFake1);
      HelperFunctions.ordenarListadoDescendentePorPropiedadDistintaDeFecha(listadoFake1); // ORDENA POR ID ASC.
      this.setListadoSolicitudes(listadoFake1);
      return this.fake_promise;
    } else {
      // url = `${this.generalURL}/api/AutorizacionesViajes/Consultar`; // PRUEBAS API LOCAL
      url = `/api/AutorizacionesViajes/Consultar`;
    }
    return new Promise((resolve, reject) => {
      // this._httpService.post(url, datosPeticion, {headers:this.headers}) // PRUEBAS API LOCAL
      this._httpService.post(url, datosPeticion)
        .subscribe((respuesta: IApiRespuesta) => {
          const listadoSolicitudes = respuesta.Datos;
          this.calcularNumeroViajerosParaListado(listadoSolicitudes);
          listadoSolicitudes.forEach((item: any) => {
            item["_Estado"] = this.calcularEstadoSolicitud(item);
            item["_precioMas"] = this.calcularPrecioMas(item);
            item["_radioKm"] = this.calcularRadioKm(item);
          });

          this.setListadoSolicitudes(listadoSolicitudes);
          this.loading$.next(false);
          resolve(true);
        }, (error: HttpErrorResponse) => {
          this.loading$.next(false);
          console.log(`Se obtuvo un error al obtener las solicitudes: ${error.message}`);
          reject(error);
        });
    });
  }

  // OBTENER LISTADO AUTORIZACIONES DEL AUTORIZADOR 
  // (Estado distinto de Utilizada(0 | 1))
  getSolicitudesAutorizacionAutorizador(rangoFechas = null): Promise<any> {
    const datosPeticion = new PetConsultarSolicitudes({ FechaDesde: rangoFechas ? rangoFechas[0] : null, FechaHasta: rangoFechas ? rangoFechas[1] : null });
    let url = "";
    if (environment.pruebas || environment.demo) {
      const listadoFake2: any[] = LISTADO_SOLICITUDES_PENDIENTES;
      this.calcularNumeroViajerosParaListado(listadoFake2);
      HelperFunctions.ordenarListadoDescendentePorPropiedadDistintaDeFecha(listadoFake2);
      this.setListadoSolicitudesAutorizador(listadoFake2);
      return this.fake_promise;
    } else {
      // url = `${this.generalURL}/api/AutorizacionesViajes/Consultar/Autorizador`; // PRUEBAS API LOCAL
      url = `/api/AutorizacionesViajes/Consultar/Autorizador`;
    }
    return new Promise((resolve, reject) => {
      // this._httpService.post(url, datosPeticion, {headers:this.headers}) // PRUEBAS API LOCAL
      this._httpService.post(url, datosPeticion)
        .subscribe((respuesta: IApiRespuesta) => {
          const listadoSolicitudes = respuesta.Datos;
          this.calcularNumeroViajerosParaListado(listadoSolicitudes);
          listadoSolicitudes.forEach((item: any) => {
            item["_Estado"] = this.calcularEstadoSolicitud(item);
            item["_precioMas"] = this.calcularPrecioMas(item);
            item["_radioKm"] = this.calcularRadioKm(item);
          });
          this.setListadoSolicitudesAutorizador(listadoSolicitudes);
          this.loading$.next(false);
          resolve(true);
        }, (error: HttpErrorResponse) => {
          this.loading$.next(false);
          console.log(`Se obtuvo un error al obtener las solicitudes: ${error.message}`);
          reject(error.message);
        });
    });
  }

  calcularNumeroViajerosParaListado(listado: SolicitudAutorizacionModel[]): void {
    listado.forEach((item) => {
      item._NumeroViajeros = item.Viajeros && item.Viajeros.length > 0 ? item.Viajeros.length : 1;
    });
  }

  calcularNumeroViajeros(solicitud: SolicitudAutorizacionModel): number {
    return solicitud.Viajeros && solicitud.Viajeros.length > 0 ? solicitud.Viajeros.length : 1;
  }

  getLoading(): Observable<boolean> {
    return this.loading$;
  }

  // CREAR SOLICITUD DE AUTORIZACIÓN
  crearSolicitudAutorizacion(solicitud: SolicitudAutorizacionModel, autorizadores: InfoAutorizador[]): Promise<any> {
    solicitud.Autorizadores = autorizadores.map((a) => {
      return a.Id
    });
    const datosPeticion = solicitud;

    let url = "";
    let listado = this.listadoSolicitudesAutorizacion$.getValue();

    if (environment.pruebas || environment.demo) {
      solicitud.Id = this.create_random_ID();
      solicitud.FechaSolicitud = new Date().toISOString(); // esto genera Z pero es para Demo
      solicitud.EstadoRespuesta = this.calcularEstadoRandom();
      solicitud.FechaRespuesta = solicitud.EstadoRespuesta == RespuestaSolicitudValores.Aprobada ? solicitud.FechaSolicitud : null;
      solicitud.MotivoDenegacion = solicitud.EstadoRespuesta == RespuestaSolicitudValores.Denegada ? 'Causa 1' : null;
      solicitud["_Estado"] = this.calcularEstadoSolicitud(solicitud);
      solicitud["_precioMas"] = this.calcularPrecioMas(solicitud);
      solicitud["_radioKm"] = this.calcularRadioKm(solicitud);

      console.log('solicitud', solicitud);
      listado.unshift(solicitud);
      this.setListadoSolicitudes(listado);
      return this.fake_promise;
    } else {
      // url = `${this.generalURL}/api/AutorizacionesViajes/Crear`; // PRUEBAS API LOCAL
      url = `/api/AutorizacionesViajes/crear`;
    }
    return new Promise((resolve, reject) => {
      // this._httpService.post(url, datosPeticion, {headers:this.headers}) // PRUEBAS API LOCAL
      this._httpService.post(url, datosPeticion)
        .subscribe((respuesta: IApiRespuesta) => {
          const nuevaSolicitud = respuesta.Datos;
          nuevaSolicitud["_Estado"] = this.calcularEstadoSolicitud(nuevaSolicitud);
          nuevaSolicitud["_precioMas"] = this.calcularPrecioMas(nuevaSolicitud);
          nuevaSolicitud["_radioKm"] = this.calcularRadioKm(nuevaSolicitud);
          this.calcularNumeroViajerosParaListado([nuevaSolicitud]);
          listado.unshift(nuevaSolicitud);
          this.setListadoSolicitudes(listado);
          resolve(true);
        }, (error: HttpErrorResponse) => {
          console.log(`Se obtuvo un error al crear la solicitud: ${error.message}`);
          reject(error.message);
        });
      // las Apis habrán modificado temporalmente las reglas para los viajeros y en la nueva búsqueda, ya vendrán cambiadas para que el usuario pueda 'continuar'
    });
  }

  obtenerRamaSinIdCliente(viajero): { id: number, rama: string } {
    let rama = [...viajero.rama];
    rama.splice(0, 1);
    const ramaSinClienteId = rama.join('|');
    return { id: viajero.id_cliente, rama: ramaSinClienteId };
  }

  // ELIMINAR SOLICITUD 
  eliminarSolicitudAutorizacion(solicitudId: number): Promise<any> {
    const datosPeticion = new PetBorrarSolicitud({ IdSolicitud: solicitudId });
    let listado = this.listadoSolicitudesAutorizacion$.getValue();
    let url = "";
    if (environment.pruebas || environment.demo) {
      const index = listado.findIndex((item) => {
        return item.Id == solicitudId;
      });
      if (index >= 0) listado.splice(index, 1);
      this.setListadoSolicitudes(listado);
      return this.fake_promise;
    } else {
      // url = `${this.generalURL}/api/AutorizacionesViajes/BorrarPorId`;  // PRUEBAS API LOCAL
      url = `/api/AutorizacionesViajes/BorrarPorId`;
    }

    return new Promise((resolve, reject) => {
      // this._httpService.post(url, datosPeticion, {headers: this.headers})  // PRUEBAS API LOCAL
      this._httpService.post(url, datosPeticion)
        .subscribe(
          (respuesta: IApiRespuesta) => {
            const index = listado.findIndex((item) => {
              return item.Id == solicitudId;
            });
            if (index >= 0) listado.splice(index, 1);
            this.setListadoSolicitudes(listado);
            resolve(true);
          }, (error: HttpErrorResponse) => {
            console.log(`Se obtuvo un error al eliminar la solicitud: ${error.message}`);
            reject(error.message);
          }
        );
    });
  }

  // ACTUALIZAR SOLICITUD (APROBADA/DENEGADA)
  actualizarEstadoSolicitud(solicitudId: any, datos: any): Promise<any> {
    const respuestaAutorizador = datos.Denegada ? RespuestaSolicitudValores.Denegada : RespuestaSolicitudValores.Aprobada;
    const motivoDenegacion = datos.Denegada ? datos.MotivoDenegacion : null;
    const datosPeticion = new PetActualizarSolicitud(
      {
        IdSolicitud: solicitudId,
        Respuesta: respuestaAutorizador,
        MotivoDenegacion: motivoDenegacion,
        RadioKm: datos.RadioKm,
        PrecioMas: datos.PrecioMas
      });
    let listado = this.listadoSolicitudesAutorizacion$.getValue();
    let listadoAutorizador = this.listadoSolicitudesAutorizacionAutorizador$.getValue();
    let url = "";
    let solicitud: SolicitudAutorizacionModel;

    if (environment.pruebas || environment.demo) {
      const indexA = listado.findIndex((item) => {
        return item.Id == solicitudId;
      });
      if (indexA >= 0) {
        solicitud = listado[indexA];
        solicitud.EstadoRespuesta = respuestaAutorizador;
        solicitud["_Estado"] = this.calcularEstadoSolicitud(solicitud);
        solicitud["_precioMas"] = this.calcularPrecioMas(solicitud);
        solicitud["_radioKm"] = this.calcularRadioKm(solicitud);
        this.calcularNumeroViajerosParaListado([solicitud]);
        this.setListadoSolicitudes(listado);
      }
      const indexB = listadoAutorizador.findIndex((item) => {
        return item.Id == solicitudId;
      });
      if (indexB >= 0) {
        solicitud = listadoAutorizador[indexB];
        solicitud.EstadoRespuesta = respuestaAutorizador;
        solicitud["_Estado"] = this.calcularEstadoSolicitud(solicitud);
        solicitud["_precioMas"] = this.calcularPrecioMas(solicitud);
        solicitud["_radioKm"] = this.calcularRadioKm(solicitud);
        this.calcularNumeroViajerosParaListado([solicitud]);
      }
      this.setListadoSolicitudesAutorizador(listadoAutorizador);

      return this.fake_promise;
    } else {
      // url = `${this.generalURL}/api/AutorizacionesViajes/GuardarRespuesta`; // PRUEBAS API LOCAL
      url = `/api/AutorizacionesViajes/GuardarRespuesta`;
    }
    return new Promise((resolve, reject) => {
      // this._httpService.post(url, datosPeticion, {headers:this.headers}) // PRUEBAS API LOCAL
      this._httpService.post(url, datosPeticion)
        .subscribe(
          (respuesta: IApiRespuesta) => {
            solicitud = respuesta.Datos;
            const indexA = listado.findIndex((item) => {
              return item.Id == solicitudId;
            });
            if (indexA >= 0) listado[indexA] = solicitud;
            solicitud["_Estado"] = this.calcularEstadoSolicitud(solicitud);
            solicitud["_precioMas"] = this.calcularPrecioMas(solicitud);
            solicitud["_radioKm"] = this.calcularRadioKm(solicitud);
            this.calcularNumeroViajerosParaListado([solicitud]);
            listado[indexA] = solicitud;
            this.setListadoSolicitudes(listado);
            const indexB = listadoAutorizador.findIndex((item) => {
              return item.Id == solicitudId;
            });
            if (indexB >= 0) listadoAutorizador[indexB] = solicitud;
            this.setListadoSolicitudesAutorizador(listadoAutorizador);

            resolve(true);
          }, (error: HttpErrorResponse) => {
            console.log(`Se obtuvo un error al actualizar la solicitud: ${error.message}`);
            reject(error.message);
          }
        );
    });

  }

  setPlataformaAPI(plataforma: any) {
    this.plataformaAPI$.next(plataforma);
  }

  setSolicitudSeleccionada(solicitud): void {
    this._solicitudSeleccionada.next(solicitud);
  }

  calcularEstadoRandom(): any { // para asignar un estado con un número del 1 al 3.
    const randomIndex = this.getRandomInt(1, 3);
    const estadosSolicitudArray = HelperFunctions.getArrayValoresEnumerable(RespuestaSolicitudValores, 'number');
    return estadosSolicitudArray[randomIndex];
  }

  setListadoSolicitudes(listado): void {
    this.listadoSolicitudesAutorizacion$.next(listado);
  }

  setListadoSolicitudesAutorizador(listado): void {
    this.listadoSolicitudesAutorizacionAutorizador$.next(listado);
  }

  formatearPrecioToLocale(precio: number, idioma?: string, moneda?: string, numeroDecimales?: number): string {
    return Funciones.formatearPrecioToLocale(precio, idioma, moneda, numeroDecimales);
  }

  getStringFecha(fecha, hasTime = false): string {
    return hasTime ? Funciones.getDateTimeNoUSA(fecha) : Funciones.getDateNoUSA(fecha);
  }

  create_random_ID(): number {
    return this.getRandomInt(1, 500);
  }

  getRandomInt(min, max) { // max excluido
    return Math.floor(Math.random() * (max - min)) + min;
  }

  traducirEnumTipoProducto(): any[] {
    let tiposDeProducto = [];
    for (const key in TipoProductoEtiquetaTraducidaValores) {
      if (Object.prototype.hasOwnProperty.call(TipoProductoEtiquetaTraducidaValores, key) && typeof TipoProductoEtiquetaTraducidaValores[key] === 'number') {
        tiposDeProducto.push({ name: this._translateService.instant(key), code: TipoProductoEtiquetaTraducidaValores[key], esApartamento: false });
        if (TipoProductoEtiquetaTraducidaValores[key] == '1') {
          tiposDeProducto.push({ name: this._translateService.instant('mis-presupuestos.vista-detalle.hotel.header.titulo'), code: 1, esApartamento: true });
        }
      }
    }

    return tiposDeProducto;
  }

  traducirEnumEstadosCalculados(): any[] {
    let estadosCalculados = [];
    for (const key in EstadoCalculadoEtiquetaTraducidaValores) {
      if (Object.prototype.hasOwnProperty.call(EstadoCalculadoEtiquetaTraducidaValores, key) && typeof EstadoCalculadoEtiquetaTraducidaValores[key] === 'number') {
        estadosCalculados.push({ name: this._translateService.instant(key), code: EstadoCalculadoEtiquetaTraducidaValores[key] });
      }
    }
    return estadosCalculados;
  }

  obtenerRegistroDeSolicitudAprobada(solicitud: SolicitudAutorizacionModel, tipoProducto: TipoProductoValores): ItemUltimosRegistros {
    // TODO: implementar si procede
    return {
      Id: 0,
      "FechaCeacion": solicitud.FechaSolicitud.toString(),
      // "EmpresaViajeros": 476, // viene en el objeto parámetro de asignarUbicacion(item) en ultimas-busquedas pero no se corresponde con interfaz
      "FechaInicio": solicitud.FechaInicio.toString(),
      "FechaFin": solicitud.FechaFin.toString(),
      "Oferta": 99,
      "OfertaCliente": 99,
      "Habitaciones": null,
      "Adultos": solicitud._NumeroViajeros,
      "Niños": 0,
      "Clase": null,
      "Origen": {
        "Latitud": solicitud.CoordenadasOrigen.Latitud,
        "Longitud": solicitud.CoordenadasOrigen.Longitud,
        "Direccion": solicitud.CodigoOrigen,
        "Pais": null,
        "Ciudad": solicitud.CodigoOrigen,
        "Aeropuerto": true
      },
      "Destino": {
        "Latitud": solicitud.CoordenadasDestino.Latitud,
        "Longitud": solicitud.CoordenadasDestino.Longitud,
        "Direccion": solicitud.CodigoDestino,
        "Pais": null,
        "Ciudad": solicitud.Destino,
        "Aeropuerto": true
      }
    };
    return {
      Id: 0,
      FechaCeacion: '',
      FechaInicio: '',
      FechaFin: '',
      Oferta: 0,
      OfertaCliente: 0,
      Habitaciones: 0,
      Adultos: 0,
      Niños: 0,
      Clase: '',
      Origen: {
        Latitud: 0,
        Longitud: 0,
        Direccion: '',
        Pais: '',
        Ciudad: '',
        Aeropuerto: true,
      },
      Destino: {
        Latitud: 0,
        Longitud: 0,
        Direccion: '',
        Pais: '',
        Ciudad: '',
        Aeropuerto: true,
      },
    };
  }

  obtenerRutaAutorreservaPorProducto(tipoProducto): string {
    let ruta = '';
    switch (tipoProducto) {
      case 1:
        ruta = '/autorreserva/hoteles';
        break;
      case 2:
        ruta = '/autorreserva/vuelos';
        break;
      case 3:
        ruta = '/autorreserva/rentacar';
        break;
      case 5:
        ruta = '/autorreserva/trenes';
        break;
      case 9:
        ruta = '/autorreserva/transfers';
        break;
    }
    return ruta;
  }

  calcularEstadoSolicitud(solicitud: SolicitudAutorizacionModel): EstadoCalculadoValores {
    let estado = null;
    if (!solicitud) return estado;
    if (solicitud.FechaBorrado != null) return EstadoCalculadoValores.Borrada;
    if (solicitud.EstadoRespuesta == RespuestaSolicitudValores.Denegada) {
      return EstadoCalculadoValores.Denegada;
    }
    if (solicitud.FechaUtilizada != null) {
      estado = EstadoCalculadoValores.Utilizada;
    } else if (solicitud.Caducada) {
      estado = EstadoCalculadoValores.Caducada;
    } else if (solicitud.EstadoRespuesta == RespuestaSolicitudValores.Aprobada) {
      estado = EstadoCalculadoValores.AprobadaSinUsar;
    } else {
      estado = EstadoCalculadoValores.PendienteAprobacion;
    }
    return estado;
  }

  calcularTextoEtiquetaEstadoCalculado(estadoCalculado): string {
    let textoEtiqueta = "";
    switch (estadoCalculado) {
      case EstadoCalculadoValores.PendienteAprobacion: textoEtiqueta = this._translateService.instant('autorizaciones-viajes.detalle-solicitud.estados-calculados.pendiente-aprobacion');
        break;
      case EstadoCalculadoValores.AprobadaSinUsar: textoEtiqueta = this._translateService.instant('autorizaciones-viajes.detalle-solicitud.estados-calculados.Aprobada-sin-usar');
        break;
      case EstadoCalculadoValores.Utilizada: textoEtiqueta = this._translateService.instant('autorizaciones-viajes.detalle-solicitud.estados-calculados.utilizada');
        break;
      case EstadoCalculadoValores.Caducada: textoEtiqueta = this._translateService.instant('autorizaciones-viajes.detalle-solicitud.estados-calculados.caducada');
        break;
      case EstadoCalculadoValores.Denegada: textoEtiqueta = this._translateService.instant('autorizaciones-viajes.detalle-solicitud.estados-calculados.denegada');
        break;
      case EstadoCalculadoValores.Borrada: textoEtiqueta = this._translateService.instant('autorizaciones-viajes.detalle-solicitud.estados-calculados.borrada');
        break;

      default: textoEtiqueta = this._translateService.instant('autorizaciones-viajes.detalle-solicitud.estados-calculados.desconocido');
        break;
    }
    return textoEtiqueta;
  }

  calcularColorEstadoCalculadoParaTag(estadoCalculado): string {
    let color = '';
    switch (estadoCalculado) {
      case EstadoCalculadoValores.PendienteAprobacion: color = 'warning';
        break;
      case EstadoCalculadoValores.AprobadaSinUsar: color = 'success';
        break;
      case EstadoCalculadoValores.Utilizada: color = 'primary';
        break;
      case EstadoCalculadoValores.Caducada: color = 'danger';
        break;
      case EstadoCalculadoValores.Denegada: color = 'danger';
        break;
      case EstadoCalculadoValores.Borrada: color = 'info';
        break;
    }
    return color;
  }

  calcularTextoEstadoRespuesta(respuesta: RespuestaSolicitudValores): string {
    let texto = "";
    switch (respuesta) {
      case RespuestaSolicitudValores.Aprobada: texto = this._translateService.instant('autorizaciones-viajes.listado-autorizaciones.estado.aprobada');
        break;
      case RespuestaSolicitudValores.Denegada: texto = this._translateService.instant('autorizaciones-viajes.listado-autorizaciones.estado.denegada');
        break;
      case RespuestaSolicitudValores.Pendiente: texto = this._translateService.instant('autorizaciones-viajes.listado-autorizaciones.estado.pendiente');
        break;

      default: texto = this._translateService.instant('autorizaciones-viajes.detalle-solicitud.estados-calculados.desconocido');
        break;
    }
    return texto;
  }

  montarUbicacionDeLaPeticionHotel(hotel: IHotel): string {
    // El destino debe contener el nombre del hotel, la dirección postal y la población
    const ubicacion = hotel.Nombre + " - " + hotel.Direccion + `${hotel.Poblacion ? (" - " + hotel.Poblacion) : ""}`;
    return ubicacion;
  }

  montarUbicacionDeLaPeticionEdificio(edificio: IEdificio): string {
    const ubicacion = edificio.Nombre + " - " + edificio.direccion + `${edificio.Ubicacion.Poblacion ? (" - " + edificio.Ubicacion.Poblacion) : ""}`;
    return ubicacion;
  }

  montarUbicacionDeLaPeticionTrenes(peticion: PeticionDisponibilidad, puntoViaje: PuntoViaje): string {
    return "";
    // TBD
  }

  montarUbicacionDeLaPeticionRentacar(peticion: PeticionDisponibilidadRentacar, puntoViaje: PuntoViaje): string {
    // peticion.Recogida.Nombre o NombreCompleto;
    // peticion.Devolucion.Nombre o NombreCompleto
    let ubicacion = "";
    const pais = peticion.Pais?.nombre ?? "";
    if (puntoViaje == PuntoViaje.Origen) ubicacion = peticion.Recogida.NombreCompleto;
    if (puntoViaje == PuntoViaje.Destino) ubicacion = peticion.Devolucion.NombreCompleto;
    return ubicacion + " " + pais;
  }

  montarUbicacionDeLaPeticionVuelos(peticion: PeticionDisponibilidadVuelos, tipoViaje: TipoViaje) {
    // peticion.aeropuertos.ida.Ciudad
    // peticion.aeropuertos.vuelta.Ciudad
    let ubicacion = "";
    const aeropuertoIda = (peticion.aeropuertos.ida.Nombre ?? "") + " " + (peticion.aeropuertos.ida.Ciudad ?? "") + " " + (peticion.aeropuertos.ida.Pais ?? "");
    const aeropuertoVuelta = (peticion.aeropuertos.vuelta.Nombre ?? "") + " " + (peticion.aeropuertos.vuelta.Ciudad ?? "") + " " + (peticion.aeropuertos.vuelta.Pais ?? "");
    if (tipoViaje == TipoViaje.Ida) ubicacion = aeropuertoIda;
    if (tipoViaje == TipoViaje.Vuelta) ubicacion = aeropuertoVuelta;
    return ubicacion;
  }

  montarUbicacionDeLaPeticionTransfer(peticion: any, puntoViaje: PuntoViaje) { // no se pudo importar por duplicado de nombre del modelo de peticion (el de trenes se llama igual)
    // peticion.Origen.Direccion;
    // peticion.Destino.Direccion;
    let ubicacion = "";
    if (puntoViaje == PuntoViaje.Origen) ubicacion = peticion.Origen.Direccion ?? "";
    if (puntoViaje == PuntoViaje.Destino) ubicacion = peticion.Destino.Direccion ?? "";
    return ubicacion;
  }

  comprobarHaySolicitudesPendientes(): boolean {
    return this.listadoSolicitudesAutorizacionAutorizador$.value.some((item) => {
      return item["_Estado"] == EstadoCalculadoValores.PendienteAprobacion;
    });
  }

  calcularRadioKm(solicitud: SolicitudAutorizacionModel): number {
    if (!solicitud) return null;
    if (solicitud["_Estado"] != EstadoCalculadoValores.AprobadaSinUsar) return null;
    const respuestaAprobacion = solicitud.Autorizadores.find((item) => {
      return item.FechaRespuesta && item.Respuesta == RespuestaSolicitudValores.Aprobada;
    });
    if (respuestaAprobacion) return respuestaAprobacion.RadioKm;
    return null;
  }

  calcularPrecioMas(solicitud: SolicitudAutorizacionModel): number {
    if (!solicitud) return null;
    if (solicitud["_Estado"] != EstadoCalculadoValores.AprobadaSinUsar) return null;
    const respuestaAprobacion = solicitud.Autorizadores.find((item) => {
      return item.FechaRespuesta && item.Respuesta == RespuestaSolicitudValores.Aprobada;
    });
    if (respuestaAprobacion) return respuestaAprobacion.PrecioMas;
    return null;
  }

  calcularNochesAlojamiento(fechaInicio: Date | string, fechaFin: Date | string): number {
    const entrada = dayjs(fechaInicio);
    const salida = dayjs(fechaFin);
    return salida.diff(entrada, 'days');
  }
}
