<div
  class="contenedor-input"
  #contenedor
  (click)="switchActivo()"
  [id]="_id"
  [class.contenedor-input--disabled]="disabled"
  [pTooltip]="disabled ? disabledTooltip : ''"
  tooltipPosition="top"
  tooltipStyleClass="tooltip-alerta"
>
  <div class="contenedor-input__label">
    <ng-content select="[label]"></ng-content>
  </div>
  <div class="contenedor-input__cuerpo">
    <ng-content select="[cuerpo]"></ng-content>
  </div>
  <div class="contenedor-input__cuerpo--placeholder" *ngIf="placeholder">
    {{ placeholder }}
  </div>

  <div class="contenedor-input__texto-footer">
    <ng-content select="[footer]"></ng-content>
  </div>
</div>
