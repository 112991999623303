import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public urlUsuarioIntentaAcceder = '';
  private _tiempoSesion = new BehaviorSubject<number>(null);
  public tiempoSesion$ = this._tiempoSesion.asObservable();

  private _ultimaAccion = new BehaviorSubject<dayjs.Dayjs>(null);
  ultimaAccion$ = this._ultimaAccion.asObservable();
  constructor(private http: HttpClient) {}

  public get tiempoSesion() {
    return this._tiempoSesion.getValue();
  }

  public setTiempoSesion() {
    const LSTiempoSesion = localStorage.getItem('Sesion_expira_en');
    if (LSTiempoSesion) {
      this._tiempoSesion.next(parseInt(LSTiempoSesion) * 1000);
    }
  }

  public getToken(): string {
    return localStorage.getItem('user_token');
  }

  public isAuthenticated(url: string): boolean {
    const token = this.getToken();
    if (!token || this.SesionCaducada) {
      this.urlUsuarioIntentaAcceder = url;
      return false;
    }
    return true;
  }

  public setUltimaAccion(fechaUltimaAccion = dayjs()) {
    if (fechaUltimaAccion) {
      localStorage.setItem('ActionTemp', fechaUltimaAccion.format('DD-MM-YYYY HH:mm:ss'));
    }

    this._ultimaAccion.next(fechaUltimaAccion);
  }

  public get SesionCaducada() {
    if ((this._ultimaAccion.getValue() && this.getTiempoSesionGastado() <= this.tiempoSesion) || environment.demo) {
      return false;
    }
    return true;
  }

  public getTiempoSesionGastado() {
    return dayjs().diff(this._ultimaAccion.getValue());
  }

  public mantenerSesionActiva() {
    return this.http.get(`/api/KeepAlive`);
  }
}
