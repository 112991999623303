import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ServicioGeneralService } from 'src/app/servicios/servicio-general.service';

@Component({
  selector: 'app-banderas',
  templateUrl: './banderas.component.html',
  styleUrls: ['./banderas.component.scss']
})
export class BanderasComponent implements OnInit, OnDestroy {
  @Input() reload = false;
  @Input() tipo: tipos;

  public banderas: IBandera[] = [
    {
      lenguaje: 'es-ES',
      texto: 'Español',
      nombreIMG: 'espana'
    },
    {
      lenguaje: 'en-GB',
      texto: 'English',
      nombreIMG: 'reino-unido'
    },
    {
      lenguaje: 'pt-PT',
      texto: 'Português',
      nombreIMG: 'portugal'
    },
    {
      lenguaje: 'fr-FR',
      texto: 'Français',
      nombreIMG: 'francia'
    }
  ];

  banderaSeleccionada: IBandera;
  _peticion: Subscription;
  constructor(private servicioGeneral: ServicioGeneralService) {}
  ngOnInit() {
    this._peticion = this.servicioGeneral.lenguaje$.subscribe((lenguaje) => {
      this.banderaSeleccionada = this.banderas.find((bandera) => bandera.lenguaje == lenguaje);
    });
  }

  ngOnDestroy() {
    this._peticion?.unsubscribe();
  }

  cambiarLenguaje(lenguaje: string) {
    this.servicioGeneral.setLenguaje(lenguaje, this.reload);
  }
}

export interface IBandera {
  lenguaje: string;
  texto: string;
  nombreIMG: string;
}

enum tipos {
  Inline = 'inline',
  Desplegable = 'desplegable',
  Dropdown = 'dropdown'
}
