import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { Table } from 'primeng/table';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { EstadoCalculadoValores, RespuestaSolicitudValores, SolicitudAutorizacionModel, TipoProductoValores } from 'src/app/autorreserva/autorreserva-compartido/modelos';
import { AutorizacionesViajesService } from 'src/app/servicios/autorizaciones-viajes.service';
import Swal, { SweetAlertIcon } from 'sweetalert2';
import { HelperFunctions } from '../autorizaciones-viajes-helper-functions';
import { HttpErrorResponse } from '@angular/common/http';



@Component({
  selector: 'app-autorizaciones-viajes-listado',
  templateUrl: './autorizaciones-viajes-listado.component.html',
  styleUrls: ['./autorizaciones-viajes-listado.component.scss'],
  providers: [MessageService]
})
export class AutorizacionesViajesListadoComponent implements OnInit, OnDestroy {

  @ViewChild('autorizacionesTabla') autorizacionesTabla: Table;
  listadoAutorizaciones: SolicitudAutorizacionModel[];
  subscripcion: Subscription;
  loadingSubscripcion: Subscription;
  loading: boolean;
  tiposDeProducto: any[] = [];
  respuestas = RespuestaSolicitudValores;
  placeholderSelect: string;
  reportePaginaActualPaginador: string;
  textoFiltro: any;
  rangeDates: Date[];
  estadosCalculados: any[] = [];
  enumEstados = EstadoCalculadoValores;
  tipoProducto = TipoProductoValores;

  constructor(
    private _autorizacionesViajesService: AutorizacionesViajesService,
    private _translateService: TranslateService,
    private _routerService: Router,
    private _messageService: MessageService
  ) {
    
    this.placeholderSelect = this._translateService.instant('autorizaciones-viajes.listado-autorizaciones.select-estados.placeholder.cualquiera');
    this.loadingSubscripcion = this._autorizacionesViajesService.loading$.subscribe((value) => {
      this.loading = value;
    });
    this.subscripcion = this._autorizacionesViajesService.listadoSolicitudesAutorizacion$.subscribe((value) => {
      this.listadoAutorizaciones = value;
      this.reportePaginaActualPaginador = this._translateService.instant('autorizaciones-viajes.listado-autorizaciones.paginador.reporte-pagina-actual.texto');
      this.listadoAutorizaciones = this.formatearCamposFechasParaFiltrosColumnas(value, ['FechaSolicitud', 'FechaInicio']);
      this.listadoAutorizaciones.forEach((solicitud)=>{
        if(solicitud.TipoProducto == TipoProductoValores.Hotel) solicitud._noches = this._autorizacionesViajesService.calcularNochesAlojamiento(solicitud.FechaInicio,solicitud.FechaFin);
      });
      console.log('listado solicitante >>>>>', this.listadoAutorizaciones);

    });
    this.tiposDeProducto = this.traducirEnumTipoProducto();
    this.estadosCalculados = this.traducirEnumEstadosCalculados();
  }
  
  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscripcion.unsubscribe();
    this.loadingSubscripcion.unsubscribe();
  }

  formatearCamposFechasParaFiltrosColumnas(value: any[], campos: string[]): SolicitudAutorizacionModel[] {
    return HelperFunctions.formatearCamposFechasParaFiltrosColumnas(value, campos);
  }
  traducirEnumTipoProducto(): any[] {
    return this._autorizacionesViajesService.traducirEnumTipoProducto();
  }

  traducirEnumEstadosCalculados():any[]{
    return this._autorizacionesViajesService.traducirEnumEstadosCalculados();
  }

  getColorEstadoCalculadoParaTag(estadoCalculado:EstadoCalculadoValores): string {
    return this._autorizacionesViajesService.calcularColorEstadoCalculadoParaTag(estadoCalculado);
  }

  getIconoProducto(tipoProducto, esApartamento: boolean | null): string {
    return HelperFunctions.getIconoProducto(tipoProducto, esApartamento);
  }

  onKey(event): void {
    this.textoFiltro = event;
  }

  getTextoEtiquetaEstadoCalculado(estadoCalculado): string { // aquí lo traduce
    return this._autorizacionesViajesService.calcularTextoEtiquetaEstadoCalculado(estadoCalculado);
  }

  hndTagRespuestaClick(autorizacion): void {
    this.mostrarAlertInfoEstado(autorizacion);
  }

  hndTagEstadoClick(autorizacion): void {
    // TBD
  }

  mostrarAlertInfoEstado(autorizacion: SolicitudAutorizacionModel): void {
    const esDenegada = autorizacion.EstadoRespuesta == RespuestaSolicitudValores.Denegada;
    const esAprobada = autorizacion.EstadoRespuesta == RespuestaSolicitudValores.Aprobada;
    const texto = esDenegada ? this._translateService.instant('autorizaciones-viajes.listado-autorizaciones.estado.motivo-denegacion') : esAprobada ? this._translateService.instant('autorizaciones-viajes.listado-autorizaciones.header.fecha-aprobacion') : '';
    const info = esDenegada ? autorizacion.MotivoDenegacion : esAprobada ? this._autorizacionesViajesService.getStringFecha(autorizacion.FechaRespuesta, true) : this._translateService.instant('autorizaciones-viajes.comun-productos.modal-estado.texto.pendiente-respuesta');
    Swal.fire({
      title: this._translateService.instant('autorizaciones-viajes.listado-autorizaciones.header.estado'),
      html: `<div style="padding-bottom: 0.5em;"><b>${texto}</b></div><div>${info}</div>${esAprobada ? '<div style="padding: 0.5em 0;"><span><b>' + this._translateService.instant('autorizaciones-viajes.listado-autorizaciones.header.fecha-caducidad') + '</b></span></div><div>' + this._autorizacionesViajesService.getStringFecha(autorizacion.FechaInicio, true) + '</div>' : ''}`,
      icon: 'info',
      showCloseButton: true,
      allowEnterKey: true
    })
      .then((result) => {

      });
  }

  hndAccionClick(autorizacion, eliminar = false): void { // TODO: TBD
    // solamente se pueden eliminar las pendientes de aprobación
    if (eliminar && autorizacion["_Estado"] == EstadoCalculadoValores.PendienteAprobacion) {
      this.eliminarSolicitud(autorizacion);
      return;
    }
  }

  eliminarSolicitud(autorizacion: SolicitudAutorizacionModel): void {
    Swal.fire({
      title: this._translateService.instant('mis-presupuestos.comun-productos.modal-confirmar.titulo.confirmacion'),
      text: `${this._translateService.instant('autorizaciones-viajes.comun-productos.modal-confirmar.texto.eliminar')} (${autorizacion.Destino})`,
      icon: 'warning',
      showCloseButton: true,
      showCancelButton: true,
      cancelButtonText: this._translateService.instant('mis-presupuestos.comun-productos.modal-confirmar.boton.cancelar'),
      confirmButtonColor: '#6a88b1',
      cancelButtonColor: '#7f8187',
      confirmButtonText: this._translateService.instant('mis-presupuestos.comun-productos.modal-confirmar.boton.eliminar'),
    }).then((result) => {
      if (result.isConfirmed) this._autorizacionesViajesService.eliminarSolicitudAutorizacion(autorizacion.Id).then((respuesta) => {
        this.autorizacionesTabla._totalRecords = this.listadoAutorizaciones.length;
        const mensajeA = this._translateService.instant('autorizaciones-viajes.comun-productos.modal-confirmar.mensaje.eliminar-exito');
        this.mostrarToast('success', mensajeA);
      })
        .catch((error) => {
          const mensajeB = this._translateService.instant('autorizaciones-viajes.comun-productos.modal-confirmar.mensaje.eliminar-error');
          this.mostrarToast('error', mensajeB);
        });
      else if (result.dismiss.toString() == 'cancel') return;
    });
  }

  private mostrarToast(tipo: string, titulo: string): void {
    this._messageService.add({ severity: tipo, summary: titulo });
  }

  buscarServicioAprobado(autorizacion: SolicitudAutorizacionModel): void {
    const tipoProducto = autorizacion.TipoProducto;
    this.asignarValoresAFormularioBusqueda(autorizacion, tipoProducto);
  }

  asignarValoresAFormularioBusqueda(solicitudAprobada: SolicitudAutorizacionModel, tipoProducto): void { // PRUEBA:
    const registro = this._autorizacionesViajesService.obtenerRegistroDeSolicitudAprobada(solicitudAprobada, tipoProducto); // TODO: implementar función en servicio

    // ir al componente de busqueda dispo del tipo de producto que corresponda
    const url = this._autorizacionesViajesService.obtenerRutaAutorreservaPorProducto(tipoProducto);
    const navigationExtras: NavigationExtras = { state: { datosNuevaBusqueda: registro } };
    this._routerService.navigateByUrl(url, navigationExtras);
    // this._autoReservaService.seleccionarRegistro(registro);
  }

  hndConsultarPorRangoFechas(rangoFechas:any[]):void{
    this._autorizacionesViajesService.getSolicitudesAutorizacion(rangoFechas)
    .then()
    .catch((errorResponse: HttpErrorResponse)=>{
      const textoAviso = this.evaluarErrorFechas(errorResponse);
      this.mostrarSweetAlert('mis-presupuestos.comun-productos.alerts.titulo.aviso',textoAviso,'warning');
    });
  }

  evaluarErrorFechas(errorResponse: HttpErrorResponse): string {
    let claveTextoError ='admin.comun.error';
    if (errorResponse.statusText != 'Bad Request') return claveTextoError;
    const textoError = errorResponse.error?.Message?.toLowerCase() as string;
    if(textoError.includes('incomplet')) claveTextoError = 'autorizaciones-viajes.listado-autorizaciones.rango-fechas.error-incompleto';
    if(textoError.includes('año')) claveTextoError = 'autorizaciones-viajes.listado-autorizaciones.rango-fechas.error-limites';
    
    return claveTextoError;
  }

  mostrarSweetAlert(claveTitulo: string, claveTexto: string, icono: SweetAlertIcon):void{
    Swal.fire({
      title: `${this._translateService.instant(claveTitulo)}`,
      text: `${claveTexto ? this._translateService.instant(claveTexto) : ''}`,
      icon: icono,
      showConfirmButton:true,
      confirmButtonText:'Ok',
      confirmButtonColor: '#6a88b1',
      showCloseButton: true,
      allowOutsideClick:true
    }).then((result) => {
    });

  }

  hndLimpiarSeleccionCalendario():void{
    this._autorizacionesViajesService.getSolicitudesAutorizacion(null)
    .then()
    .catch()
  }

  hndIrAHoy():void{
    this._autorizacionesViajesService.getSolicitudesAutorizacion(null)
    .then()
    .catch()
  }

  getNombreEmpresaConJerarquia(ramificacionConDatos:any[]):string{
    const ramificacion = [...ramificacionConDatos];
    let nombresRamaEmpresa: string[] = [];
    ramificacion.forEach((item, index) => {
      if(index != 0) nombresRamaEmpresa.push(item.Nombre);
    });
    return nombresRamaEmpresa.join(' - ');
  }

  getTooltipTextRadioKm(radioKm: number):string {
    return `${this._translateService.instant('mis-presupuestos.comun-productos.tooltip.radio-km')}: ${radioKm}`;
  }
}
