import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BanderasComponent } from './banderas.component';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [BanderasComponent],
  imports: [CommonModule, MatButtonModule, MatMenuModule, DropdownModule, FormsModule],
  exports: [BanderasComponent]
})
export class BanderasModule {}
