import { Injectable } from '@angular/core';
import { CanLoad, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { PermisosService } from 'src/app/servicios/permisos.service';

@Injectable({
  providedIn: 'root'
})
export class CanLoadAdministracionUsuariosGuard implements CanLoad {
  constructor(private permisosService: PermisosService, private router: Router) {}
  canLoad(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(this.permisosService.permisoAdministracionUsuarios()) return true;
    this.router.navigate(['/']);
    return false;
  }
}
