import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-campana-avisos',
  templateUrl: './campana-avisos.component.html',
  styleUrls: ['./campana-avisos.component.scss']
})
export class CampanaAvisosComponent implements OnInit {

  cantidadAvisosSinLeer: number = 100;


  constructor(private _routerService: Router) { }

  ngOnInit(): void {
  }

  hndCampanaClick(): void {
    this.cantidadAvisosSinLeer = 0;
    this._routerService.navigate(['/autorizaciones-viajes']);
  }

}
