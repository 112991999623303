import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NavbarRoutingModule } from './navbar-routing.module';
import { NavbarComponent } from './navbar.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TranslateModule } from '@ngx-translate/core';
import { BanderasModule } from '../banderas/banderas.module';
import { BotonAyudaModule } from '../boton-ayuda/boton-ayuda.module';
import { AutorizacionesViajesModule } from 'src/app/autorizaciones-viajes/autorizaciones-viajes.module';
import { CampanaAvisosModule } from '../campana-avisos/campana-avisos.module';

@NgModule({
  declarations: [NavbarComponent],
  imports: [
    CommonModule,
    NavbarRoutingModule,
    MatToolbarModule,
    MatMenuModule,
    TranslateModule,
    BanderasModule,
    BotonAyudaModule,
    AutorizacionesViajesModule,
    CampanaAvisosModule
  ],
  exports: [NavbarComponent]
})
export class NavbarModule { }
