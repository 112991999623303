export const LISTADO_SOLICITUDES_PENDIENTES = [
  {
      "FechaInicio": "2023-01-23T23:00:00",
      "FechaFin": null,
      "FechaSolicitud": "2022-11-15T10:55:27",
      "FechaBorrado": null,
      "Id": 1207,
      "TipoProducto": 2,
      "Cantidad": 1,
      "Precio": 1267.50,
      "Notas": "Solicitud Demo",
      "Origen": "Adolfo Suárez Madrid Barajas International Airport Madrid España",
      "Destino": "Los Angeles, todos los aeropuertos Los Angeles, CA Estados Unidos",
      "CodigoOrigen": "MAD",
      "CodigoDestino": "LAX",
      "FechaUtilizada": null,
      "CoordenadasOrigen": null,
      "CoordenadasDestino": null,
      "_NumeroViajeros": 1,
      "Viajeros": [
        {
          "Email": "viajero@consultiatravel.es",
          "RamificacionConDatos": [
            {
              "Nombre": "CL33337, Apellidos",
              "EsFacturable": false,
              "EsPersona": true
            },
            {
              "Nombre": "CL234, Apellidos",
              "EsFacturable": true,
              "EsPersona": false
            },
            {
              "Nombre": "CL29485, Apellidos",
              "EsFacturable": false,
              "EsPersona": false
            }
          ]
        }
      ],
      "Autorizadores": [
        {
          "Email": "miautorizador@consultiatravel.com",
          "Telefono": "555555555",
          "NombreCompleto": "CL2, Apellidos",
          "FechaRespuesta": null,
          "Respuesta": null,
          "MotivoDenegacion": null,
          "RadioKm": null,
          "PrecioMas": null
        }
      ],
      "EstadoRespuesta": 3,
      "Caducada": false,
      "NombreSolicitante": "CL33337, Apellidos",
      "EmailSolicitante": "viajero@consultiatravel.es",
      "_Estado": 1,
      "_radioKm": null,
      "_precioMas": null
  },
  {
    "Id": 118,
    "TipoProducto": 2,
    "Cantidad": 1,
    "FechaInicio": "2022-06-29T22:00:00",
    "Caducada": true,
    "Utilizada": false,
    "EstadoRespuesta": null,
    "FechaFin": "2022-06-29T22:00:00",
    "Precio": 2087.37,
    "Notas": "Solicitud Demo",
    "CodigoOrigen": "MAD",
    "CodigoDestino": "CHI",
    "Origen": "Madrid",
    "Destino": "Chicago,  IL",
    "CoordenadasOrigen": null,
    "CoordenadasDestino": null,
    "_NumeroViajeros": 1,
    "FechaSolicitud": "2022-06-13T10:28:29",
    "Viajeros": [{
      "Email": "viajero@consultiatravel.es",
      "RamificacionConDatos": [
        {
          "Nombre": "CL33337, Apellidos",
          "EsFacturable": false,
          "EsPersona": true
        },
        {
          "Nombre": "CL234, Apellidos",
          "EsFacturable": true,
          "EsPersona": false
        },
        {
          "Nombre": "CL29485, Apellidos",
          "EsFacturable": false,
          "EsPersona": false
        }
      ]
    }],
    "_Estado": 4,
    "FechaRespuesta": "2022-06-13T10:28:29.790Z",
    "MotivoDenegacion": null,
    "FechaBorrado": null,
    "Autorizadores": [
      {
        "Email": "miautorizador@consultiatravel.com",
        "Telefono": "555555555",
        "NombreCompleto": "CL2, Apellidos",
        "FechaRespuesta": null,
        "Respuesta": 1,
        "MotivoDenegacion": null,
        "RadioKm": 1,
        "PrecioMas": 5
      }
    ],
    "_radioKm": 1,
    "_precioMas": 5
  },
  {
    "Id": 127,
    "TipoProducto": 2,
    "Cantidad": 1,
    "FechaInicio": "2022-06-14T22:00:00.000Z",
    "Caducada": true,
    "Utilizada": false,
    "EstadoRespuesta": 2,
    "FechaFin": "2022-06-15T22:00:00.000Z",
    "Precio": 2617.41,
    "Notas": "Solicitud Demo",
    "CodigoOrigen": "MAD",
    "CodigoDestino": "ONT",
    "Origen": "Madrid",
    "Destino": "Ontario",
    "CoordenadasOrigen": null,
    "CoordenadasDestino": null,
    "_NumeroViajeros": 1,
    "FechaSolicitud": "2022-06-09T06:07:25.959Z",
    "Viajeros": [{
      "Email": "viajero@consultiatravel.es",
      "RamificacionConDatos": [
        {
          "Nombre": "CL33337, Apellidos",
          "EsFacturable": false,
          "EsPersona": true
        },
        {
          "Nombre": "CL234, Apellidos",
          "EsFacturable": true,
          "EsPersona": false
        },
        {
          "Nombre": "CL29485, Apellidos",
          "EsFacturable": false,
          "EsPersona": false
        }
      ]
    }],
    "_Estado": 5,
    "FechaRespuesta": "2022-06-09T07:07:25.959Z",
    "MotivoDenegacion": "Hay otra solicitud para la misma fecha.",
    "FechaBorrado": null,
    "Autorizadores": [
      {
        "Email": "miautorizador@consultiatravel.com",
        "Telefono": "555555555",
        "NombreCompleto": "CL2, Apellidos",
        "FechaRespuesta": null,
        "Respuesta": 2,
        "MotivoDenegacion": "Hay otra solicitud para la misma fecha.",
        "RadioKm": null,
        "PrecioMas": null
      }
    ],
    "_radioKm": null,
    "_precioMas": null
  },
  {
    "Id": 82,
    "TipoProducto": 2,
    "Cantidad": 1,
    "FechaInicio": "2022-11-09T22:00:00.000Z",
    "Caducada": false,
    "Utilizada": false,
    "EstadoRespuesta": 1,
    "FechaFin": "2022-11-09T22:00:00.000Z",
    "Precio": 302.22,
    "Notas": "Solicitud Demo",
    "CodigoOrigen": "MAD",
    "CodigoDestino": "VLC",
    "Origen": "",
    "Destino": "Valencia",
    "CoordenadasOrigen": null,
    "CoordenadasDestino": null,
    "_NumeroViajeros": 1,
    "FechaSolicitud": "2022-06-03T10:14:45.677Z",
    "Viajeros": [{
      "Email": "viajero@consultiatravel.es",
      "RamificacionConDatos": [
        {
          "Nombre": "CL33337, Apellidos",
          "EsFacturable": false,
          "EsPersona": true
        },
        {
          "Nombre": "CL234, Apellidos",
          "EsFacturable": true,
          "EsPersona": false
        },
        {
          "Nombre": "CL29485, Apellidos",
          "EsFacturable": false,
          "EsPersona": false
        }
      ]
    }],
    "_Estado": 2,
    "FechaRespuesta": "2022-11-15T09:10:56",
    "FechaBorrado": null,
    "Autorizadores": [
      {
        "Email": "miautorizador@consultiatravel.com",
        "Telefono": "555555555",
        "NombreCompleto": "CL2, Apellidos",
        "FechaRespuesta": null,
        "Respuesta": 1,
        "MotivoDenegacion": null,
        "RadioKm": 5,
        "PrecioMas": 10
      }
    ],
    "_radioKm": 5,
    "_precioMas": 10

  },
  {
    "Id": 83,
    "TipoProducto": 2,
    "Cantidad": 1,
    "FechaInicio": "2022-06-09T22:00:00",
    "Caducada": true,
    "Utilizada": false,
    "EstadoRespuesta": 1,
    "FechaFin": "2022-06-09T22:00:00",
    "Precio": 302.22,
    "Notas": "Solicitud Demo",
    "CodigoOrigen": "MAD",
    "CodigoDestino": "VLC",
    "Origen": "",
    "Destino": "Valencia",
    "CoordenadasOrigen": null,
    "CoordenadasDestino": null,
    "_NumeroViajeros": 1,
    "FechaSolicitud": "2022-06-03T10:14:45.677Z",
    "Viajeros": [{
      "Email": "viajero@consultiatravel.es",
      "RamificacionConDatos": [
        {
          "Nombre": "CL33337, Apellidos",
          "EsFacturable": false,
          "EsPersona": true
        },
        {
          "Nombre": "CL234, Apellidos",
          "EsFacturable": true,
          "EsPersona": false
        },
        {
          "Nombre": "CL29485, Apellidos",
          "EsFacturable": false,
          "EsPersona": false
        }
      ]
    }],
    "_Estado": 4,
    "FechaRespuesta": "2022-06-04T10:14:45.677Z",
    "MotivoDenegacion": null,
    "FechaBorrado": null,
    "Autorizadores": [
      {
        "Email": "miautorizador@consultiatravel.com",
        "Telefono": "555555555",
        "NombreCompleto": "CL2, Apellidos",
        "FechaRespuesta": "2022-06-04T10:14:45.677Z",
        "Respuesta": 1,
        "MotivoDenegacion": null,
        "RadioKm": 5,
        "PrecioMas": 10
      }
    ],
    "_radioKm": 5,
    "_precioMas": 10


  },
  {
    "Id": 84,
    "TipoProducto": 2,
    "Cantidad": 1,
    "FechaInicio": "2022-11-11T22:00:00",
    "Caducada": false,
    "Utilizada": false,
    "EstadoRespuesta": null,
    "FechaFin": "2022-11-11:00:00",
    "Precio": 302.22,
    "Notas": "Solicitud Demo",
    "CodigoOrigen": "MAD",
    "CodigoDestino": "VLC",
    "Origen": "",
    "Destino": "Valencia",
    "CoordenadasOrigen": null,
    "CoordenadasDestino": null,
    "_NumeroViajeros": 1,
    "FechaSolicitud": "2022-06-03T10:14:45",
    "Viajeros": [{
      "Email": "viajero@consultiatravel.es",
      "RamificacionConDatos": [
        {
          "Nombre": "CL33337, Apellidos",
          "EsFacturable": false,
          "EsPersona": true
        },
        {
          "Nombre": "CL234, Apellidos",
          "EsFacturable": true,
          "EsPersona": false
        },
        {
          "Nombre": "CL29485, Apellidos",
          "EsFacturable": false,
          "EsPersona": false
        }
      ]
    }],
    "_Estado": 1,
    "FechaRespuesta": null,
    "MotivoDenegacion": null,
    "FechaBorrado": null,
    "Autorizadores": [
      {
        "Email": "miautorizador@consultiatravel.com",
        "Telefono": "555555555",
        "NombreCompleto": "CL2, Apellidos",
        "FechaRespuesta": null,
        "Respuesta": null,
        "MotivoDenegacion": null,
        "RadioKm": null,
        "PrecioMas": null
      }
    ],
    "_radioKm": null,
    "_precioMas": null
  },
  {
    "Id": 455,
    "TipoProducto": 2,
    "Cantidad": 1,
    "FechaInicio": "2022-06-21T22:00:00.000Z",
    "Caducada": true,
    "Utilizada": false,
    "EstadoRespuesta": 1,
    "FechaFin": "2022-06-21T22:00:00.000Z",
    "Precio": 829.37,
    "Notas": "Solicitud Demo",
    "CodigoOrigen": "ALC",
    "CodigoDestino": "LAX",
    "Origen": "",
    "Destino": "Los Ángeles",
    "CoordenadasOrigen": null,
    "CoordenadasDestino": null,
    "_NumeroViajeros": 1,
    "FechaSolicitud": "2022-06-07T10:20:53.685Z",
    "Viajeros": [{
      "Email": "viajero@consultiatravel.es",
      "RamificacionConDatos": [
        {
          "Nombre": "CL33337, Apellidos",
          "EsFacturable": false,
          "EsPersona": true
        },
        {
          "Nombre": "CL234, Apellidos",
          "EsFacturable": true,
          "EsPersona": false
        },
        {
          "Nombre": "CL29485, Apellidos",
          "EsFacturable": false,
          "EsPersona": false
        }
      ]
    }],
    "_Estado": 4,
    "FechaRespuesta": null,
    "MotivoDenegacion": null,
    "FechaBorrado": null,
    "Autorizadores": [
      {
        "Email": "miautorizador@consultiatravel.com",
        "Telefono": "555555555",
        "NombreCompleto": "CL2, Apellidos",
        "FechaRespuesta": null,
        "Respuesta": null,
        "MotivoDenegacion": null,
        "RadioKm": null,
        "PrecioMas": null
      }
    ],
    "_radioKm": null,
    "_precioMas": null
  },
  {
    "Id": 463,
    "TipoProducto": 2,
    "Cantidad": 1,
    "FechaInicio": "2022-06-07T22:00:00.000Z",
    "Caducada": true,
    "Utilizada": false,
    "EstadoRespuesta": 1,
    "FechaFin": "2022-06-08T22:00:00.000Z",
    "Precio": 550.63,
    "Notas": "Solicitud Demo",
    "CodigoOrigen": "MAD",
    "CodigoDestino": "VLC",
    "Origen": "",
    "Destino": "Valencia",
    "CoordenadasOrigen": null,
    "CoordenadasDestino": null,
    "_NumeroViajeros": 1,
    "FechaSolicitud": "2022-06-07T10:31:16.264Z",
    "Viajeros": [{
      "Email": "viajero@consultiatravel.es",
      "RamificacionConDatos": [
        {
          "Nombre": "CL33337, Apellidos",
          "EsFacturable": false,
          "EsPersona": true
        },
        {
          "Nombre": "CL234, Apellidos",
          "EsFacturable": true,
          "EsPersona": false
        },
        {
          "Nombre": "CL29485, Apellidos",
          "EsFacturable": false,
          "EsPersona": false
        }
      ]
    }],
    "_Estado": 4,
    "FechaRespuesta": null,
    "MotivoDenegacion": null,
    "FechaBorrado": null,
    "Autorizadores": [
      {
        "Email": "miautorizador@consultiatravel.com",
        "Telefono": "555555555",
        "NombreCompleto": "CL2, Apellidos",
        "FechaRespuesta": null,
        "Respuesta": null,
        "MotivoDenegacion": null,
        "RadioKm": null,
        "PrecioMas": null
      }
    ],
    "_radioKm": null,
    "_precioMas": null
  },
  {
    "Id": 78,
    "TipoProducto": 2,
    "Cantidad": 1,
    "FechaInicio": "2022-06-14T22:00:00.000Z",
    "Caducada": true,
    "Utilizada": false,
    "EstadoRespuesta": 1,
    "FechaFin": "2022-06-15T22:00:00.000Z",
    "Precio": 1897.45,
    "Notas": "Solicitud Demo",
    "CodigoOrigen": "MAD",
    "CodigoDestino": "LAX",
    "Origen": "",
    "Destino": "Los Ángeles",
    "CoordenadasOrigen": null,
    "CoordenadasDestino": null,
    "_NumeroViajeros": 1,
    "FechaSolicitud": "2022-06-07T10:41:39.248Z",
    "Viajeros": [{
      "Email": "viajero@consultiatravel.es",
      "RamificacionConDatos": [
        {
          "Nombre": "CL33337, Apellidos",
          "EsFacturable": false,
          "EsPersona": true
        },
        {
          "Nombre": "CL234, Apellidos",
          "EsFacturable": true,
          "EsPersona": false
        },
        {
          "Nombre": "CL29485, Apellidos",
          "EsFacturable": false,
          "EsPersona": false
        }
      ]
    }],
    "_Estado": 4,
    "FechaRespuesta": null,
    "MotivoDenegacion": null,
    "FechaBorrado": null,
    "IdAutorizador": null


  },
  {
    "Id": 89,
    "TipoProducto": 2,
    "Cantidad": 1,
    "FechaInicio": "2022-06-09T22:00:00.000Z",
    "Caducada": true,
    "Utilizada": false,
    "EstadoRespuesta": 1,
    "FechaFin": "2022-06-09T22:00:00.000Z",
    "Precio": 302.22,
    "Notas": "Solicitud Demo",
    "CodigoOrigen": "MAD",
    "CodigoDestino": "VLC",
    "Origen": "",
    "Destino": "Valencia",
    "CoordenadasOrigen": null,
    "CoordenadasDestino": null,
    "_NumeroViajeros": 1,
    "FechaSolicitud": "2022-06-03T10:14:45.677Z",
    "Viajeros": [{
      "Email": "viajero@consultiatravel.es",
      "RamificacionConDatos": [
        {
          "Nombre": "CL33337, Apellidos",
          "EsFacturable": false,
          "EsPersona": true
        },
        {
          "Nombre": "CL234, Apellidos",
          "EsFacturable": true,
          "EsPersona": false
        },
        {
          "Nombre": "CL29485, Apellidos",
          "EsFacturable": false,
          "EsPersona": false
        }
      ]
    }],
    "_Estado": 1,
    "FechaRespuesta": null,
    "MotivoDenegacion": null,
    "FechaBorrado": null,
    "Autorizadores": [
      {
        "Email": "miautorizador@consultiatravel.com",
        "Telefono": "555555555",
        "NombreCompleto": "CL2, Apellidos",
        "FechaRespuesta": null,
        "Respuesta": null,
        "MotivoDenegacion": null,
        "RadioKm": null,
        "PrecioMas": null
      }
    ],
    "_radioKm": null,
    "_precioMas": null
  },
  {
    "Id": 90,
    "TipoProducto": 2,
    "Cantidad": 1,
    "FechaInicio": "2022-06-09T22:00:00.000Z",
    "Caducada": true,
    "Utilizada": false,
    "EstadoRespuesta": 1,
    "FechaFin": "2022-06-09T22:00:00.000Z",
    "Precio": 302.22,
    "Notas": "Solicitud Demo",
    "CodigoOrigen": "MAD",
    "CodigoDestino": "VLC",
    "Origen": "Madrid",
    "Destino": "Valencia",
    "CoordenadasOrigen": null,
    "CoordenadasDestino": null,
    "_NumeroViajeros": 1,
    "FechaSolicitud": "2022-06-03T10:14:45.677Z",
    "Viajeros": [{
      "Email": "viajero@consultiatravel.es",
      "RamificacionConDatos": [
        {
          "Nombre": "CL33337, Apellidos",
          "EsFacturable": false,
          "EsPersona": true
        },
        {
          "Nombre": "CL234, Apellidos",
          "EsFacturable": true,
          "EsPersona": false
        },
        {
          "Nombre": "CL29485, Apellidos",
          "EsFacturable": false,
          "EsPersona": false
        }
      ]
    }],
    "_Estado": 4,
    "FechaRespuesta": "2022-06-04T10:14:45.677Z",
    "MotivoDenegacion": null,
    "FechaBorrado": null,
    "Autorizadores": [
      {
        "Email": "miautorizador@consultiatravel.com",
        "Telefono": "555555555",
        "NombreCompleto": "CL2, Apellidos",
        "FechaRespuesta": null,
        "Respuesta": 1,
        "MotivoDenegacion": null,
        "RadioKm": 1,
        "PrecioMas": 15
      }
    ],
    "_radioKm": 1,
    "_precioMas": 15
  },
  {
    "Id": 92,
    "TipoProducto": 2,
    "Cantidad": 1,
    "FechaInicio": "2022-06-09T22:00:00.000Z",
    "Caducada": true,
    "Utilizada": false,
    "EstadoRespuesta": 1,
    "FechaFin": "2022-06-09T22:00:00.000Z",
    "Precio": 302.22,
    "Notas": "Solicitud Demo",
    "CodigoOrigen": "MAD",
    "CodigoDestino": "VLC",
    "Origen": "",
    "Destino": "Valencia",
    "CoordenadasOrigen": null,
    "CoordenadasDestino": null,
    "_NumeroViajeros": 1,
    "FechaSolicitud": "2022-06-03T10:14:45.677Z",
    "Viajeros": [{
      "Email": "viajero@consultiatravel.es",
      "RamificacionConDatos": [
        {
          "Nombre": "CL33337, Apellidos",
          "EsFacturable": false,
          "EsPersona": true
        },
        {
          "Nombre": "CL234, Apellidos",
          "EsFacturable": true,
          "EsPersona": false
        },
        {
          "Nombre": "CL29485, Apellidos",
          "EsFacturable": false,
          "EsPersona": false
        }
      ]
    }],
    "_Estado": 3,
    "FechaRespuesta": null,
    "MotivoDenegacion": null,
    "FechaBorrado": null,
    "Autorizadores": [
      {
        "Email": "miautorizador@consultiatravel.com",
        "Telefono": "555555555",
        "NombreCompleto": "CL2, Apellidos",
        "FechaRespuesta": null,
        "Respuesta": null,
        "MotivoDenegacion": null,
        "RadioKm": null,
        "PrecioMas": null
      }
    ],
    "_radioKm": null,
    "_precioMas": null
  },
  {
    "Id": 56,
    "TipoProducto": 2,
    "Cantidad": 1,
    "FechaInicio": "2022-06-21T22:00:00.000Z",
    "Caducada": true,
    "Utilizada": false,
    "EstadoRespuesta": 1,
    "FechaFin": "2022-06-21T22:00:00.000Z",
    "Precio": 1587.43,
    "Notas": "Solicitud Demo",
    "CodigoOrigen": "MAD",
    "CodigoDestino": "LAX",
    "Origen": "Madrid",
    "Destino": "Los Angeles, CA",
    "CoordenadasOrigen": null,
    "CoordenadasDestino": null,
    "_NumeroViajeros": 1,
    "FechaSolicitud": "2022-06-09T07:20:11.334Z",
    "Viajeros": [{
      "Email": "viajero@consultiatravel.es",
      "RamificacionConDatos": [
        {
          "Nombre": "CL33337, Apellidos",
          "EsFacturable": false,
          "EsPersona": true
        },
        {
          "Nombre": "CL234, Apellidos",
          "EsFacturable": true,
          "EsPersona": false
        },
        {
          "Nombre": "CL29485, Apellidos",
          "EsFacturable": false,
          "EsPersona": false
        }
      ]
    }],

    "_Estado": 3,
    "FechaRespuesta": null,
    "MotivoDenegacion": null,
    "FechaBorrado": null,
    "Autorizadores": [
      {
        "Email": "miautorizador@consultiatravel.com",
        "Telefono": "555555555",
        "NombreCompleto": "CL2, Apellidos",
        "FechaRespuesta": null,
        "Respuesta": null,
        "MotivoDenegacion": null,
        "RadioKm": null,
        "PrecioMas": null
      }
    ],
    "_radioKm": null,
    "_precioMas": null
  },
  {
    "Id": 323,
    "TipoProducto": 2,
    "Cantidad": 1,
    "FechaInicio": "2022-06-14T22:00:00.000Z",
    "Caducada": true,
    "Utilizada": false,
    "EstadoRespuesta": 1,
    "FechaFin": "2022-06-14T22:00:00.000Z",
    "Precio": 2238.21,
    "Notas": "Solicitud Demo",
    "CodigoOrigen": "MAD",
    "CodigoDestino": "MEX",
    "Origen": "Madrid",
    "Destino": "Mexico City",
    "CoordenadasOrigen": null,
    "CoordenadasDestino": null,
    "_NumeroViajeros": 1,
    "FechaSolicitud": "2022-06-09T15:41:48.463Z",
    "Viajeros": [{
      "Email": "viajero@consultiatravel.es",
      "RamificacionConDatos": [
        {
          "Nombre": "CL33337, Apellidos",
          "EsFacturable": false,
          "EsPersona": true
        },
        {
          "Nombre": "CL234, Apellidos",
          "EsFacturable": true,
          "EsPersona": false
        },
        {
          "Nombre": "CL29485, Apellidos",
          "EsFacturable": false,
          "EsPersona": false
        }
      ]
    }],
    "_Estado": 4,
    "FechaRespuesta": "2022-06-09T15:41:48.463Z",
    "MotivoDenegacion": null,
    "FechaBorrado": null,
    "Autorizadores": [
      {
        "Email": "miautorizador@consultiatravel.com",
        "Telefono": "555555555",
        "NombreCompleto": "CL2, Apellidos",
        "FechaRespuesta": null,
        "Respuesta": 1,
        "MotivoDenegacion": null,
        "RadioKm": 5,
        "PrecioMas": 5
      }
    ],    
    "_radioKm": 5,
    "_precioMas": 5
  },
  {
    "FechaInicio": "2022-06-29T22:00:00",
    "Caducada": true,
    "Utilizada": false,
    "EstadoRespuesta": 1,
    "FechaFin": "2022-06-29T22:00:00",
    "FechaSolicitud": "2022-06-21T09:39:13.27",
    "Autorizadores": [
      {
        "Email": "miautorizador@consultiatravel.com",
        "Telefono": "555555555",
        "NombreCompleto": "CL2, Apellidos",
        "FechaRespuesta": null,
        "Respuesta": null,
        "MotivoDenegacion": null,
        "RadioKm": null,
        "PrecioMas": null
      }
    ],
    "Id": 96,
    "TipoProducto": 2,
    "Cantidad": 2,
    "Precio": 1699.95,
    "Notas": "Solicitud Demo",
    "Origen": "Mexico City",
    "Destino": "Madrid",
    "CodigoOrigen": "MEX",
    "CodigoDestino": "MAD",
    "CoordenadasOrigen": null,
    "CoordenadasDestino": null,
    "NombreSolicitante": "CL33337, Apellidos",
    "EmailSolicitante": "viajero@consultiatravel.es",
    "NombreEmpresa": "CL234, Apellidos|CL29485, Apellidos",
    "Viajeros": [{
      "Email": "viajero@consultiatravel.es",
      "RamificacionConDatos": [
        {
          "Nombre": "CL33337, Apellidos",
          "EsFacturable": false,
          "EsPersona": true
        },
        {
          "Nombre": "CL234, Apellidos",
          "EsFacturable": true,
          "EsPersona": false
        },
        {
          "Nombre": "CL29485, Apellidos",
          "EsFacturable": false,
          "EsPersona": false
        }
      ]
    },
    {
      "Email": "viajero@consultiatravel.es",
      "RamificacionConDatos": [
        {
          "Nombre": "CL2, Apellidos",
          "EsFacturable": false,
          "EsPersona": true
        },
        {
          "Nombre": "CL234, Apellidos",
          "EsFacturable": true,
          "EsPersona": false
        },
        {
          "Nombre": "CL29485, Apellidos",
          "EsFacturable": false,
          "EsPersona": false
        }
      ]
    }],
    "_Estado": 4,
    "_radioKm": null,
    "_precioMas": null
  },
  {
    "FechaInicio": "2022-12-30T22:00:00",
    "Caducada": false,
    "Utilizada": false,
    "EstadoRespuesta": 1,
    "FechaFin": "2022-12-31T22:00:00",
    "FechaSolicitud": "2022-06-21T09:39:13.27",
    "Autorizadores": [
      {
        "Email": "miautorizador@consultiatravel.com",
        "Telefono": "555555555",
        "NombreCompleto": "CL2, Apellidos",
        "FechaRespuesta": null,
        "Respuesta": null,
        "MotivoDenegacion": null,
        "RadioKm": null,
        "PrecioMas": null
      }
    ],
    "Id": 102,
    "TipoProducto": 2,
    "Cantidad": 2,
    "Precio": 1699.96,
    "Notas": "Solicitud Demo",
    "Origen": "Mexico City",
    "Destino": "Madrid",
    "CodigoOrigen": "MEX",
    "CodigoDestino": "MAD",
    "CoordenadasOrigen": null,
    "CoordenadasDestino": null,
    "NombreSolicitante": "CL33337, Apellidos",
    "EmailSolicitante": "viajero@consultiatravel.es",
    "NombreEmpresa": "CL234, Apellidos|CL29485, Apellidos",
    "Viajeros": [{
      "Id": 2,
      "Nombre": "CL2, Apellidos",
      "NombreEmpresa": "CL234"
    },
    {
      "Id": 33337,
      "Nombre": "CL33337, Apellidos",
      "NombreEmpresa": "CL234"
    }],
    "_Estado": 1,
    "_radioKm": null,
    "_precioMas": null
  },
  {
    "FechaInicio": "2023-06-30T22:00:00",
    "FechaFin": "2023-06-29T22:00:00",
    "FechaSolicitud": "2022-06-21T09:39:13.27",
    "Caducada": false,
    "Id": 85,
    "TipoProducto": 2,
    "Cantidad": 2,
    "Precio": 1432.71,
    "Notas": "Solicitud Demo",
    "Origen": "Madrid",
    "Destino": "John Wayne-Orange County Airport",
    "CodigoOrigen": "MAD",
    "CodigoDestino": "SNA",
    "CoordenadasOrigen": null,
    "CoordenadasDestino": null,
    "NombreSolicitante": "CL33337, Apellidos",
    "EmailSolicitante": "CL33337@company.es",
    "Viajeros": [{
      "Email": "viajero@consultiatravel.es",
      "RamificacionConDatos": [
        {
          "Nombre": "CL33337, Apellidos",
          "EsFacturable": false,
          "EsPersona": true
        },
        {
          "Nombre": "CL234, Apellidos",
          "EsFacturable": true,
          "EsPersona": false
        },
        {
          "Nombre": "CL29485, Apellidos",
          "EsFacturable": false,
          "EsPersona": false
        }
      ]
    },
    {
      "Email": "viajero@consultiatravel.es",
      "RamificacionConDatos": [
        {
          "Nombre": "CL2, Apellidos",
          "EsFacturable": false,
          "EsPersona": true
        },
        {
          "Nombre": "CL234, Apellidos",
          "EsFacturable": true,
          "EsPersona": false
        },
        {
          "Nombre": "CL29485, Apellidos",
          "EsFacturable": false,
          "EsPersona": false
        }]
    }],
    "_Estado": 1,
    "_NumeroViajeros": 1,
    "FechaRespuesta": null,
    "EstadoRespuesta": 3,
    "MotivoDenegacion": null,
    "FechaUtilizada": null,
    "FechaBorrado": null,
    "Autorizadores": [
      {
        "Email": "miautorizador@consultiatravel.com",
        "Telefono": "555555555",
        "NombreCompleto": "CL2, Apellidos",
        "FechaRespuesta": null,
        "Respuesta": null,
        "MotivoDenegacion": null,
        "RadioKm": null,
        "PrecioMas": null
      }
    ],
    "_radioKm": null,
    "_precioMas": null
  }
];