import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Funciones } from 'src/assets/funcionesComunes';

@Component({
  selector: 'app-precio',
  templateUrl: './precio.component.html',
  styleUrls: ['./precio.component.scss']
})
export class PrecioComponent implements OnChanges {
  @Input() precio: number;
  precioPartido: { parteEntera: string; parteDecimal: string };

  constructor() {}

  ngOnChanges() {
    this.precioPartido = Funciones.dividirEnteroDecimal(this.precio);
  }
}
