<div id="controlesRangoFechas" class="col-12">
  <form [formGroup]="rangoFechasForm" action="">
    <fieldset class="border p-2">
      <div class="row px-3">
        <div class="col-12 pb-3 flex contenedor-calendario">
          <div class="contenedor-calendario__label">
            <label for="fechaDesde">
              {{'autorizaciones-viajes.listado-autorizaciones.rango-fechas.label-input' | translate}} ({{
              'admin.comun.opcional' | translate
              }}*)
            </label>&nbsp;&nbsp;
          </div> 
          <div class="contenedor-calendario__calendar">
            <p-calendar formControlName="rangoFechas" [minDate]="minDateValue" [maxDate]="maxDateValue"
            dateFormat="dd/mm/yy" [showIcon]="true" inputId="fechaDesde" [readonlyInput]="true" selectionMode="range"
            showButtonBar="true" (onClearClick)="hndLimpiarClick()" (onTodayClick)="hndIrAHoyClick()"></p-calendar>
          </div>         
        </div>
        <div><span><em>*{{textoExplicativo}}</em></span></div>
      </div>
    </fieldset>
  </form>
</div>