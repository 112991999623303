import { PermisosAdministracion } from 'src/app/modelos/usuario';
import { StringDecoder } from 'string_decoder';

export enum TipoProductoValores {
  Hotel = 1,
  Vuelo = 2,
  Rentacar = 3,
  Barco = 4,
  Tren = 5,
  Transfer = 9,
}

export enum RespuestaSolicitudTextoValores {
  Pendiente = "Pendiente",
  Aprobada = "Aprobada",
  Denegada = "Denegada",

}

export enum RespuestaSolicitudValores {
  Aprobada = 1,
  Denegada = 2,
  Pendiente = 3,
}

export enum TipoProductoEtiquetaTraducidaValores {
  'mis-presupuestos.vista-detalle.hotel.header.titulo' = 1,
  'dashboard.vuelo' = 2,
  'dashboard.rent-a-car' = 3,
  'dashboard.resumen-consumos.barco' = 4,
  'dashboard.resumen-consumos.tren' = 5,
  'dashboard.transfers' = 9,
}

export enum RespuestaSolicitudEtiquetaTraducidaValores {
  'autorizaciones-viajes.listado-autorizaciones.estado.aprobada' = 1,
  'autorizaciones-viajes.listado-autorizaciones.estado.denegada' = 2,
  'autorizaciones-viajes.listado-autorizaciones.estado.pendiente' = 3,
}

export enum EstadoSolicitudEtiquetaTraducidaValores {
  'autorizaciones-viajes.listado-autorizaciones.estado.no-utilizada' = 0,
  'autorizaciones-viajes.listado-autorizaciones.estado.utilizada' = 1,
  'autorizaciones-viajes.listado-autorizaciones.estado.caducada' = 2,
}

export enum EstadoCalculadoValores{
PendienteAprobacion = 1,
AprobadaSinUsar = 2,
Utilizada = 3,
Caducada = 4,
Denegada = 5,
Borrada = 6
}

export enum EstadoCalculadoEtiquetaTraducidaValores{
'autorizaciones-viajes.detalle-solicitud.estados-calculados.pendiente-aprobacion' = 1,
'autorizaciones-viajes.detalle-solicitud.estados-calculados.Aprobada-sin-usar' = 2,
'autorizaciones-viajes.detalle-solicitud.estados-calculados.utilizada' = 3,
'autorizaciones-viajes.detalle-solicitud.estados-calculados.caducada' = 4,
'autorizaciones-viajes.detalle-solicitud.estados-calculados.denegada' = 5,
'autorizaciones-viajes.detalle-solicitud.estados-calculados.borrada' = 6
}

export enum TipoViaje{
  'Ida'= 1,
  'Vuelta'= 2,
}

export enum PuntoViaje{
  'Origen'= 1,
  'Destino' = 2
}

export enum TrayectoTrenValores{
  'Ida' = 'I',
  'Vuelta' = 'V'
}

export enum ClasesBadgeAprobada{
  'Mini' = 'mini',
  'Mediano' = 'mediano',
  'Grande' = 'grande'
}

/* MIS PRESUPUESTOS */

export class MiPresupuestoModel {
  Id: any;
  _numero: string;
  Nombre: string;
  FechaCreacion: Date | string;
  Destino: string;
  _FechaSalida: Date | string;
  Usuario: UsuarioModel;
  _numeroViajeros: number;
  Servicios: MiPresupuestoItemModel[];

  constructor(obj?) {
    obj = obj || {};
    this.Id = obj.id || null;
    this._numero = obj._numero || null;
    this.Nombre = obj.Nombre || null;
    this.FechaCreacion = obj.FechaCreacion || null;
    this.Destino = obj.Destino || null;
    this._FechaSalida = obj._FechaSalida || null;
    this.Usuario = obj.Usuario || null;
    this._numeroViajeros = obj._numeroViajeros;
    this.Servicios = obj.Servicios || [];

  }

}

export class UsuarioModel {
  Id: number;
  Nombre: string;
  Apellidos: string;
  // nombreDepartamento: string;
  nombreEmpresa: string;
  telefonoContacto: string;
  emailContacto: string;
  CorreoGestor: string;
  Resp_compra: boolean;
  active: boolean;
  PuedeReservar: boolean;
  // CC: any;
  // CO: number;
  Lenguaje: string;
  PermisosAdministracion: PermisosAdministracion;
  // ControlGastos: ControlGastos;

  constructor(obj?) {
    obj = obj || {};
    this.Id = obj.Id || 0;
    this.Nombre = obj.Nombre || '';
    this.Apellidos = obj.Apellidos || '';
    // this.nombreDepartamento = obj.nombreDepartamento || '';
    this.nombreEmpresa = obj.nombreEmpresa || '';
    this.CorreoGestor = obj.CorreoGestor || '';
    this.telefonoContacto = obj.telefonoContacto || '';
    this.emailContacto = obj.emailContacto || '';
    this.Resp_compra = obj.Resp_compra || false;
    this.active = obj.active || false;
    this.PuedeReservar = obj.PuedeReservar || false;
    // this.CC = obj.CC || null;
    // this.CO = obj.CO || -1;
    this.Lenguaje = obj.Lenguaje || null;
    this.PermisosAdministracion = obj.PermisosAdministracion || null;
    // this.ControlGastos = obj.ControlGastos || null;
  }
}

export class MiPresupuestoItemModel { // ofertas
  Id: any;
  CumpleReglas: boolean;
  TipoProducto: TipoProductoValores; // 1,2,3,5,9
  Descripcion: string; // H(), T (label en vista detalle), H (tipoHabitacion), R (MarcaModelo), TX (ModelosVehiculo)
  Categoria: string; // H, R, TX (TipoVehiculo + Categoria)
  CodigoAgrupador: string; // T ida y vuelta, para relacionarlo con su correspondiente ida
  EsIdaVuelta: boolean; // T, V ida, vuelta
  CantidadItem: number; // H (num hab.) // quitar y meter en datosComplementarios
  FechaInicio: string; // H(entrada) // T,V // R, TX
  FechaFin: string;// T,V // R
  PrecioTotal: number;
  ProveedorLogo: string;
  NombreProveedor: string; // H,T,R, TX(conector), V(conector)
  CodigoOrigen: string; // T,V
  CodigoDestino: string; // T,V
  Origen: string; // T, TX,  R
  Destino: string; // T, TX, R
  CoordLatitudOrigen: number;  // excepto Trenes
  CoordLongitudOrigen: number;
  CoordLatitudDestino: number; // H
  CoordLongitudDestino: number; // H
  ModeloVehiculo: string; // T(tipo tren), R, TX
  NumeroViajeros: number; // TX
  DatosComplementarios: DatosComplementariosModel | string | JSON;
  ListaViajeros?: ViajeroPresupuestoModel[];  // para crear el presupuesto y anyadir registro a la tabla Viajeros-Presupuestos

  constructor(obj?) {
    obj = obj || {};
    this.Id = obj.Id;
    this.CumpleReglas = obj.CumpleReglas || false;
    this.TipoProducto = obj.TipoProducto;
    this.Descripcion = obj.Descripcion || "";
    this.Categoria = obj.Categoria || "";
    this.CodigoAgrupador = obj.CodigoAgrupador || "";
    this.EsIdaVuelta = obj.EsIdaVuelta || false;
    this.CantidadItem = obj.CantidadItem || 1;
    this.FechaInicio = obj.FechaInicio;
    this.FechaFin = obj.FechaFin || this.FechaInicio; // evitar SqlDateTime overflow con 1/1/0001
    this.PrecioTotal = obj.PrecioTotal;
    this.ProveedorLogo = obj.ProveedorLogo;
    this.NombreProveedor = obj.NombreProveedor || "";
    this.CodigoOrigen = obj.CodigoOrigen || "";
    this.CodigoDestino = obj.CodigoDestino || "";
    this.Origen = obj.Origen || "";
    this.Destino = obj.Destino || "";
    this.CoordLatitudOrigen = obj.CoordLatitudOrigen || null;
    this.CoordLongitudOrigen = obj.CoordLongitudOrigen || null;
    this.CoordLatitudDestino = obj.CoordLatitudDestino || null;
    this.CoordLongitudDestino = obj.CoordLongitudDestino || null;
    this.ModeloVehiculo = obj.ModeloVehiculo || "";
    this.NumeroViajeros = obj.NumeroViajeros || 1;
    this.DatosComplementarios = obj.DatosComplementarios || {};
    this.ListaViajeros = obj.ListaViajeros || [];

  }
}

export class ViajeroPresupuestoModel {
  id: number;
  rama: number[];
  ramaTexto: string;

  constructor(obj?) {
    obj = obj || {};
    this.id = obj.id;
    this.rama = obj.rama || null;
    this.ramaTexto = obj.ramaTexto || "";
  }
}


export class DatosComplementariosModel {
  nombre: string;
  direccion: string;
  ciudad: string;
  datosContacto: string; // TX (dato conocido posteriormente)
  condicionesTarifa: StringDecoder;
  regimen: string;
  nombreOperador: string;
  numeroVuelo: string;
  cantidadEquipajeIncluidoDeMano: string;
  colorDeMano: string;
  cantidadEquipajeIncluidoEnBodega: string;
  colorEnBodega: string;
  prioridad: string;
  nombreMarketing: string;
  cantidadItem: number;
  textoEscala: string;
  agrupadorTrayectos: string;
  esIda: boolean;
  cambios: number;
  textoCambios: string;
  cancelacion: number;
  textoCancelacion: string;
  numeroViajeros?: number;
  esApartamento: boolean | null;

  constructor(obj?) {
    obj = obj || {};
    this.nombre = obj.nombre || "";
    this.direccion = obj.direccion || "";
    this.ciudad = obj.ciudad || "";
    this.datosContacto = obj.datosContacto || "";
    this.condicionesTarifa = obj.condicionesTarifa || "";
    this.regimen = obj.regimen || "";
    this.nombreOperador = obj.nombreOperador || "";
    this.numeroVuelo = obj.numeroVuelo || ""; // cambiado a string vacío porque null entra en conflicto con deserialización desde BD
    this.cantidadEquipajeIncluidoDeMano = obj.cantidadEquipajeIncluidoDeMano || "";
    this.colorDeMano = obj.colorDeMano || 'white';
    this.cantidadEquipajeIncluidoEnBodega = obj.cantidadEquipajeIncluidoEnBodega || "";
    this.colorEnBodega = obj.colorEnBodega || 'white';
    this.prioridad = obj.prioridad || "";
    this.nombreMarketing = obj.nombreMarketing || "";
    this.cantidadItem = obj.cantidadItem || 1;
    this.textoEscala = obj.textoEscala || "";
    this.agrupadorTrayectos = obj.agrupadorTrayectos || "";  // TODO: analizar si se elimina prop
    this.esIda = obj.esIda;
    this.cambios = obj.cambios;
    this.textoCambios = obj.textoCambios || "";
    this.cancelacion = obj.cancelacion;
    this.textoCancelacion = obj.textoCancelacion || "";
    this.numeroViajeros = obj.numeroViajeros || 1;
    this.esApartamento = obj.esApartamento || null;
  }
}
export interface IDatosInicialesPresupuesto {
  Nombre: string;
  Destino: string;
}

export class GruposDeProductosModel {
  hoteles: MiPresupuestoItemModel[]; // para evitar problemas con el tipo de datos de DatosComplementarios del modelo (JSON|string|DatosComplementarios)
  trenes: MiPresupuestoItemModel[];
  vuelos: MiPresupuestoItemModel[];
  rentACars: MiPresupuestoItemModel[];
  transfers: MiPresupuestoItemModel[];
  barcos: MiPresupuestoItemModel[];

  constructor(obj?) {
    obj = obj || {};
    this.hoteles = obj.hoteles || [];
    this.trenes = obj.trenes || [];
    this.vuelos = obj.vuelos || [];
    this.rentACars = obj.rentACars || [];
    this.transfers = obj.transfers || [];
    this.barcos = obj.barcos || [];
  }
}

export class DatosVueloModel {
  FechaIda: string | Date;
  FechaVuelta: string | Date;
  fee: any;
  idaSeleccionada: IIdaSeleccionada;
  vueltaSeleccionada: IVueltaSeleccionada;
  idasConVueltas: IIdasConVueltas;
  Precio: number;

  constructor(obj?) {
    obj = obj || {};
    this.FechaIda = obj.FechaIda;
    this.FechaVuelta = obj.FechaVuelta;
    this.fee = obj.fee;
    this.idaSeleccionada = obj.idaSeleccionada;
    this.vueltaSeleccionada = obj.vueltaSeleccionada;
    this.idasConVueltas = obj.idasConVueltas;
    this.Precio = obj.Precio;
  }
}

export interface IIdaSeleccionada {
  AerolineasVuelosIda: any[];
  ClasesVuelosIda: string;
  Duracion: string;
  EFT: string;
  FechaLLegada: string;
  FechaSalida: string;
  Id: number;
  NumerosVuelos: string;
  Ruta: any[];
  TextoEscalas: string; // Directo / ??
  Vuelos: any[];
  ida: any; // Ruta[],Equipaje[],Vuelos[]
}
export interface IVueltaSeleccionada {
  AerolineasVuelosVuelta: any[];
  ClasesVuelosVuelta: string;
  CondicionesVuelo: any[];
  CumpleReglas: boolean;
  Duracion: string;
  Equipaje: any[];
  IconoReembolsableRojo: boolean;
  IdOferta: string; // guid
  NumerosVuelos: string; // el número en formato XYnnnn
  PVP: number;
  Reembolsable: boolean;
  Ruta: any[];
  TextoReembolsable: string;
  Vuelos: any[];
  id_guid_selected: string;

}
export interface IIdasConVueltas {
  AgrupacionesPorTrayectosVuelta: any[];
  CondicionesVuelo: any[];
  Conector: number;
  CumpleReglas: boolean;
  EquipajeIda: any[];
  IconoReembolsableRojo: boolean;
  IdOfertaSoloIda: string; // guid
  NumerosVuelos: string; // el número en formato XYnnnn
  PVPDesde: number;
  PvpConFee?: number;
  Reembolsable: boolean;
  Ruta: any[];
  TextoReembolsable: string;
}
export class PetConsultarPorId {
  id: number;

  constructor(obj?) {
    obj = obj || {};
    this.id = obj.id;
  }
}
export class PetCrear {
  presupuesto: MiPresupuestoModel;
  listaViajeros: any[];

  constructor(obj?) {
    obj = obj || {};
    this.presupuesto = obj.presupuesto;
    this.listaViajeros = obj.listaViajeros || [];
  }
}
export class PetCrearServicio {
  idPresupuesto: number;
  servicios: any[];

  constructor(obj?) {
    obj = obj || {};
    this.idPresupuesto = obj.idPresupuesto;
    this.servicios = obj.servicios || [];
  }
}
export class PetBorrar {
  id: number;

  constructor(obj?) {
    obj = obj || {};
    this.id = obj.id;
  }
}
export class PetBorrarServicio {
  idServicio: number;
  idPresupuesto: number;

  constructor(obj?) {
    obj = obj || {};
    this.idServicio = obj.idServicio;
    this.idPresupuesto = obj.idPresupuesto;
  }
}
export class PetBorrarServicios {
  idsServicios: number[];
  idPresupuesto: number;

  constructor(obj?) {
    obj = obj || {};
    this.idsServicios = obj.idsServicios;
    this.idPresupuesto = obj.idPresupuesto;
  }
}
export class PetActualizar {
  id: number;
  nombre: string;
  destino: string;

  constructor(obj?) {
    obj = obj || {};
    this.id = obj.id;
    this.nombre = obj.nombre || "";
    this.destino = obj.destino || "";
  }
}

export class PetDescargarPdf {
  idPresupuesto:number;
  nombrePresupuesto: string;
  idiomaHtml:string;
  idiomaUsuario:string;
  nombreEmpresa:string;
  constructor(obj?) {
    obj = obj || {};
    this.idPresupuesto = obj.idPresupuesto;
    this.nombrePresupuesto = obj.nombrePresupuesto;
    this.idiomaHtml = obj.idiomaHtml;
    this.idiomaUsuario = obj.idiomaUsuario;
    this.nombreEmpresa = obj.nombreEmpresa;
  }
}
export class IApiRespuesta {
  InfoOpcional?: string;
  Datos: any[] | any | null;
}
export interface IBorrarServicioData {
  idServicio: number;
  idPresupuesto: number;
  tipoProducto: TipoProductoValores;
  gruposDeServicios: number;
}

export interface IInformacionViajeros {
  difiereNumViajeros: boolean;
  presupuestoVacio: boolean;
}


/* AUTORIZACIONES VIAJES */

export class SolicitudAutorizacionModel {
  Id?: number;
  // Id_Oferta?:string; // prueba enviar
  TipoProducto: TipoProductoValores; // 1,2,3,5,9
  Cantidad: number;
  FechaInicio: string; // H(entrada) // T,V // R, TX
  FechaFin: string;// T,V // R
  Precio: number;
  Notas: string;
  CodigoOrigen: string; // T,V
  CodigoDestino: string; // T,V
  Origen: string; // T (códigos de estación), TX,  R  V(los IATA)
  Destino: string; // T, TX, R
  CoordenadasOrigen: Coordenadas;  // excepto Trenes
  CoordenadasDestino: Coordenadas; // H
  _NumeroViajeros?: number; // TX
  FechaSolicitud?: string;
  EstadoRespuesta?: RespuestaSolicitudValores; // 1,2,3 (Pendiente, Aprobada, Denegada)  // campo calculado en api
  FechaRespuesta?: string; // pertenece a tabla Autorizadores-Solicitudes
  MotivoDenegacion?: string; 
  Caducada: boolean; // campo calculado en api
  FechaUtilizada?: string;
  FechaBorrado?: string;
  NombreSolicitante?: string;
  EmailSolicitante?: string;
  PrecioLimiteAutorizado: number; // nuevo campo calculado en API
  Viajeros: ViajeroModel[];
  Autorizadores: any[];
  _Estado?: EstadoCalculadoValores;
  _precioMas?: number;
  _radioKm?: number;
  _notaComplementos?:any[]; // para poder almacenar en el campo Nota info sobre si hay complementos seleccionados
  _noches?:number; // calculado en cliente para hoteles
  DatosComplementarios: DatosComplementarios | null;

  constructor(obj?) {
    obj = obj || {};
    // this.Id_Oferta = obj.Id_Oferta || null;
    this.TipoProducto = obj.TipoProducto;
    this.Cantidad = obj.Cantidad || 1;
    this.FechaInicio = obj.FechaInicio;
    this.FechaFin = obj.FechaFin || null;
    this.Precio = obj.Precio;
    this.Notas = obj.Notas || null;
    this.CodigoOrigen = obj.CodigoOrigen || null;
    this.CodigoDestino = obj.CodigoDestino || null;
    this.Origen = obj.Origen || "";
    this.Destino = obj.Destino || "";
    this.CoordenadasOrigen = obj.CoordenadasOrigen || null;
    this.CoordenadasDestino = obj.CoordenadasDestino || null;
    this.FechaSolicitud = obj.FechaSolicitud || null;
    this.Caducada = obj.Caducada || false;
    this.NombreSolicitante = obj.NombreSolicitante || null;
    this.EmailSolicitante = obj.EmailSolicitante || null;
    this.PrecioLimiteAutorizado = obj.PrecioLimiteAutorizado || null;
    this.Viajeros = obj.Viajeros || null;
    this.Autorizadores = obj.Autorizadores || [];
    this._notaComplementos = obj._notaComplementos || [];
    this.DatosComplementarios = obj.DatosComplementarios || null;
  }
}

export class DatosComplementarios{
  EsApartamento: boolean

  constructor(obj?)
  {
    this.EsApartamento = obj.EsApartamento;
  }
}
export class Coordenadas {
  Latitud: number;
  Longitud: number;
  constructor(obj?){
    obj = obj || {};
    this.Latitud = obj.Latitud;
    this.Longitud = obj.Longitud;
  }
}

export class ViajeroModel {
  Nombre: string;
  Email: string;
  NombreEmpresaConJerarquia:string;
  RamificacionConDatos: any[];
  constructor(obj?) {
    obj = obj || {};
    this.Nombre = obj.Nombre || "";
    this.Email = obj.Email || "";
    this.NombreEmpresaConJerarquia = obj.NombreEmpresaConJerarquia || "";
    this.RamificacionConDatos = obj.RamificacionConDatos || null;

  }
}

export class RespuestaAutorizador{
  Email: string;
  Telefono:string;
  NombreCompleto: string;
  FechaRespuesta: string;
  Respuesta: RespuestaSolicitudValores; // Solamente puede ser 1 o 2.
  MotivoDenegacion: string;
  RadioKm:number;
  PrecioMas:number;
  IdAutorizador:number;
  
  constructor(obj?) {
    obj = obj || {};
    this.Email = obj.Email;
    this.Telefono = obj.Telefono || null;
    this.NombreCompleto = obj.NombreCompleto || null;
    this.FechaRespuesta = obj.FechaRespuesta || null;
    this.Respuesta = obj.Respuesta;
    this.MotivoDenegacion = obj.MotivoDenegacion || null;
    this.RadioKm = obj.RadioKm || null;
    this.PrecioMas = obj.PrecioMas || null;
    this.IdAutorizador = obj.IdAutorizador;
    
  }
}
export class PetBorrarSolicitud {
  IdSolicitud: number;

  constructor(obj?) {
    obj = obj || {};
    this.IdSolicitud = obj.IdSolicitud;
  }
}
export class PetConsultarSolicitudes {
  FechaDesde: number;
  FechaHasta: number;

  constructor(obj?) {
    obj = obj || {};
    this.FechaDesde = obj.FechaDesde;
    this.FechaHasta = obj.FechaHasta;
  }
}
export class PetConsultarSolicitudPorEstadoSolicitante {
  idSolicitante: number;
  estado: number;
  idAutorizador: number;

  constructor(obj?) {
    obj = obj || {};
    this.idSolicitante = obj.idSolicitante;
    this.estado = obj.estado;
    this.idAutorizador = obj.idAutorizador;
  }
}
export class PetConsultarSolicitudPorEstadoAutorizador {
  idAutorizador: number;
  estado: number;

  constructor(obj?) {
    obj = obj || {};
    this.idAutorizador = obj.idAutorizador;
    this.estado = obj.estado;
  }
}

export class PetConsultarSolicitudPorId {
  id: number;
  idSolicitante: number;

  constructor(obj?) {
    obj = obj || {};
    this.id = obj.id;
    this.idSolicitante = obj.idSolicitante;

  }
}
export class PetCrearSolicitud {
  Solicitud: SolicitudAutorizacionModel;

  constructor(obj?) {
    obj = obj || {};
    this.Solicitud = obj.Solicitud;
  }
}
export class PetActualizarSolicitud {
  IdSolicitud: number;
  Respuesta: RespuestaSolicitudValores;
  MotivoDenegacion: string;
  RadioKm:number;
  PrecioMas:number;

  constructor(obj?) {
    obj = obj || {};
    this.IdSolicitud = obj.IdSolicitud;
    this.Respuesta = obj.Respuesta;
    this.MotivoDenegacion = obj.MotivoDenegacion || null;
    this.RadioKm = obj.RadioKm || null;
    this.PrecioMas = obj.PrecioMas || null;

  }
}
export class PetActualizarSolicitudSinSesion {
  Token: any;
  Respuesta: RespuestaSolicitudValores;
  MotivoDenegacion: string;
  RadioKm:number;
  PrecioMas:number;

  constructor(obj?) {
    obj = obj || {};
    this.Token = obj.Token;
    this.Respuesta = obj.Respuesta;
    this.MotivoDenegacion = obj.MotivoDenegacion || null;
    this.RadioKm = obj.RadioKm || null;
    this.PrecioMas = obj.PrecioMas || null;
  }
}

export class PetConsultarSolicitudPorToken{
  Token:string;
  constructor(obj?){
    obj = obj || {};
    this.Token = obj.Token;
  }
}

export class InfoCliente {
  id: number;
  rama: string;

  constructor(obj?) {
    obj = obj || {};
    this.id = obj.id;
    this.rama = obj.rama || "";
  }
}

export class InfoAutorizador{
  Id:number;
  Nombre:string;
  constructor(obj?){
    obj = obj || {};
    this.Id = obj.Id;
    this.Nombre = obj.Nombre;
  }
}

export class DatosActualizacionSolicitud {
  Denegada: boolean;
  MotivoDenegacion: string;
  RadioKm:number;
  PrecioMas:number;

  constructor(obj?){
    obj = obj || {};
    this.Denegada = obj.Denegada || false;
    this.MotivoDenegacion = obj.MotivoDenegacion || null;
    this.RadioKm = obj.RadioKm || null;
    this.PrecioMas = obj.PrecioMas || null;
  }
}

export interface IOpcionesRadioKm{
  radio:string;
  valor:number;
  unidadMedida:string;
}
export interface IOpcionesPrecioMas{
  precio:string;
  valor:number;
  moneda:string;
}

export class ComplementosSeleccionados{
  Nombre:string;
  Trayecto:TrayectoTrenValores;
  Precio:number;
 
  constructor(obj?) {
    obj = obj || {};
    this.Nombre = obj.Nombre || '';
    this.Trayecto = obj.Trayecto;
    this.Precio = obj.Precio;
  }
}


