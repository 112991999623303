import { Injectable } from '@angular/core';
import { Usuario, UsuarioAdmin } from '../modelos/usuario';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoginService } from './login.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ServicioGeneralService {
  private _loading = new BehaviorSubject<boolean>(false);
  loading$ = this._loading.asObservable();

  private _NavegadorIE = new BehaviorSubject<boolean>(false);
  NavegadorIE$ = this._NavegadorIE.asObservable();

  private _lenguaje = new BehaviorSubject<string>(
    localStorage.getItem('lang') ? localStorage.getItem('lang') : 'es-ES'
  );
  lenguaje$ = this._lenguaje.asObservable();

  private _propsSidebar = new BehaviorSubject<{ mostrar: boolean; fijada: boolean }>({
    mostrar: false,
    fijada: JSON.parse(localStorage.getItem('sidebarFijada')) ?? true
  });
  propsSidebar$ = this._propsSidebar.asObservable();

  constructor(private http: HttpClient, private loginService: LoginService) {}

  setLoading(valor: boolean) {
    this._loading.next(valor);
  }

  setNavegadorIE(valor: boolean) {
    this._NavegadorIE.next(valor);
  }

  getNavegadorIE() {
    return this._NavegadorIE.value;
  }

  comprobarLenguaje(lenguajeComprobar: string) {
    const lenguajes = ['es-ES', 'en-GB', 'pt-PT', 'fr-FR'];
    lenguajeComprobar = lenguajeComprobar.replace('_', '-');
    return lenguajes.find((lenguaje) => lenguajeComprobar == lenguaje) ? lenguajeComprobar : lenguajes[0];
  }

  async setLenguaje(lenguaje: string, reload = true) {
    const estaLogueado = this.loginService.getLoggedInValue();
    lenguaje = this.comprobarLenguaje(lenguaje);
    const lenguajeActual = localStorage.getItem('lang');
    if (lenguajeActual != lenguaje) {
      localStorage.setItem('lang', lenguaje);
      if (estaLogueado) await this.seleccionarLenguajeServidor(lenguaje).toPromise();

      if (reload) {
        window.location.reload();
      } else {
        this._lenguaje.next(lenguaje);
      }
    }
  }

  getLenguaje() {
    return this._lenguaje.value;
  }

  seleccionarLenguajeServidor(lenguaje) {
    if (!environment.demo) {
      return this.http.get(`/api/SeleccionarLenguaje?id=${lenguaje}`);
    } else {
      return of(true);
    }
  }

  switchMostrarSidebar() {
    const valorActual = { ...this._propsSidebar.value };
    this._propsSidebar.next({ ...valorActual, mostrar: !valorActual.mostrar });
  }

  setMostrarSidebar(valor) {
    const valorActual = { ...this._propsSidebar.value };
    if (valorActual.mostrar != valor) this._propsSidebar.next({ ...valorActual, mostrar: valor });
  }

  switchFijarSidebar() {
    const valorActual = { ...this._propsSidebar.value };
    this._propsSidebar.next({ ...valorActual, fijada: !valorActual.fijada });
    localStorage.setItem('sidebarFijada', JSON.stringify(this._propsSidebar.value.fijada));
  }

  getCountries() {
    const lenguaje = this.getLenguaje().split('-')[1].toLowerCase();
    return this.http.get(`assets/misc/paises/${this.getLenguaje()}/countries.json`).pipe(
      map((data: any) => {
        return data.sort((a, b) => a.alpha2 === lenguaje ? -1 : 1);
      })
    )
  }
}
