<div id="elegirAutorizadorForm" class="p-dynamicdialog">
  <div class="main">
    <autoreserva-contenedor-campo-formulario style="width: -webkit-fill-available;">
      <div cuerpo>
        <p-multiSelect
          #AutorizadoresMultiSelect
          name="selector-autorizadores"
          [options]="autorizadores"
          optionLabel="Nombre"
          defaultLabel="{{'autorizaciones-viajes.comun-productos.modal-elegir-autorizador.select.placeholder' | translate}}"
          [(ngModel)]="opcionesSeleccionadas"
          [filter]="true"
          emptyFilterMessage="{{'primeng.emptyMessage' | translate}}"
          [virtualScroll]="true"
          styleClass="contenedor-input__input contenedor-input__input--lugares"
          [disabled]="autorizadores?.length < 1"
    ></p-multiSelect>
      </div>
    </autoreserva-contenedor-campo-formulario>
    <div *ngIf="autorizadores?.length < 1" class="texto-explicativo">
      <span style="font-style:italic;font-weight:600">*{{'autorizaciones-viajes.comun-productos.modal-elegir-autorizador.texto-aviso-sin-autorizador' | translate}}.</span>
    </div>
  </div>
  <div class="p-dialog-footer">
    <button pButton type="button" class="btn boton-primario" (click)="hndCrearBtnClick()"
      [disabled]="opcionesSeleccionadas.length < 1">
      {{ 'autorizaciones-viajes.comun-productos.modal-elegir-autorizador.boton.continuar' | translate }}
    </button>
    <button pButton type="button" class="btn boton-primario-hollow" (click)="hndCancelarBtnClick()">
      {{ 'autorizaciones-viajes.detalle-solicitud.modal-motivo-denegacion.boton.cancelar' | translate }}
    </button>
  </div>
</div>
