import { SolicitudAutorizacionModel, TipoProductoValores } from "../autorreserva/autorreserva-compartido/modelos";

export class HelperFunctions {

  public static getIconoProducto(tipo: TipoProductoValores, esApartamento: boolean = false): string {
    let claseIcono;
    switch (tipo) {
      case 1:
        if(esApartamento)
        {
          claseIcono = 'fas fa-building';
        }
        else{
        claseIcono = 'fas fa-hotel';
        }
        break;
      case 2:
        claseIcono = 'fas fa-plane';
        break;
      case 3:
        claseIcono = 'fas fa-car';
        break;
      case 5:
        claseIcono = 'fas fa-train';
        break;
      case 4:
        claseIcono = 'fas fa-ship';
        break;
      case 9:
        claseIcono = 'fas fa-taxi';
        break;

      default: claseIcono = 'fas fa-question';
        break;
    }

    return claseIcono;
  }

  // ejemplos tipoDelValor: 'number', 'string'
  public static getArrayValoresEnumerable(enumerable: any, tipoDelValor: string): any[] {
    let arrayValores = [];
    for (const key in enumerable)
      if (typeof enumerable[key] == tipoDelValor) arrayValores.push(enumerable[key]);
    return arrayValores;
  }

  public static encontrarValorEnEnumerable(value, enumList): any {
    for (const k in enumList) if (enumList[k] == value) return enumList[k];
    return null;
  }

  // ordena por campo a excepción de los tipo fecha (por defecto por campo Id)
  public static ordenarListadoDescendentePorPropiedadDistintaDeFecha(listado: any, propiedad?: string) {
    if (!listado || listado.length < 1) return;
    propiedad = propiedad || "Id";
    listado.sort((itemA, itemB) => {
      return itemA[propiedad] < itemB[propiedad] ? 1 : -1;
    });
  }

  // solo ordena por campo de tipo fecha
  public static ordenarListadoDescendentePorPropiedadFecha(listado: any, propiedadFecha: string): void {
    if (!listado || listado.length < 1) return;
    listado.sort((itemA, itemB) => {
      const fechaA = new Date(itemA[propiedadFecha]).getTime();
      const fechaB = new Date(itemB[propiedadFecha]).getTime();
      return fechaA < fechaB ? 1 : -1;
    });
  }

  // solo ordena por campo de tipo fecha
  public static ordenarListadoPorPropiedadFechaYOrden(listado: any, propiedadFecha: string, asc: boolean = false): void {
    if (!listado || listado.length < 1) return;
    listado.sort((itemA, itemB) => {
      const fechaA = new Date(itemA[propiedadFecha]).getTime();
      const fechaB = new Date(itemB[propiedadFecha]).getTime();
      const condicion = asc ? (fechaA < fechaB ? -1 : 1) : (fechaA < fechaB ? 1 : -1);
      return condicion;
    });
  }

  public static formatearCamposFechasParaFiltrosColumnas(listadoDatos: any[], campos: string[]): SolicitudAutorizacionModel[] {
    listadoDatos.forEach((item) => {
      campos.forEach((campo) => {
        if (item[campo]) item[campo] = new Date(item[campo]);
      });
    });
    return listadoDatos;
  }

  public static getTipoServicio(tipoServicio: TipoProductoValores): string {
    let texto = "";
    switch (tipoServicio) {
      case 1:
        texto = "hotel";
        break;
      case 2:
        texto = "vuelo";
        break;
      case 3:
        texto = "rent a car";
        break;
      case 5:
        texto = "tren";
        break;
      case 9:
        texto = "transfer";
        break;

      default: texto = "otro tipo"
        break;
    }
    return texto;
  }

}