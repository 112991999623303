<footer class="footer">
  <div class="footer__texto">
    © 2021 Consultia Business Travel.
    <!-- | Política de Privacidad. -->
  </div>
  <div class="footer__rrss">
    <span class="rrss__icono"
      ><a href="https://www.facebook.com/ConsultiaTravel/" target="_blank"><i class="fab fa-facebook"></i></a
    ></span>
    <!-- <span class="rrss__icono"
      ><a href="https://twitter.com/consultiatravel" target="_blank"><i class="fab fa-twitter"></i></a
    ></span> -->
    <span class="rrss__icono"
      ><a href="https://www.instagram.com/consultia_/" target="_blank"><i class="fab fa-instagram"></i></a
    ></span>
    <span class="rrss__icono"
      ><a href="https://www.linkedin.com/company/consultia-travel/" target="_blank"><i class="fab fa-linkedin"></i></a
    ></span>
    <span class="rrss__icono"
      ><a href="https://www.youtube.com/channel/UCJAb1mxbyd3ty8ii-yG-0-w" target="_blank"
        ><i class="fab fa-youtube"></i></a
    ></span>
  </div>
  <!-- <a href="microsoft-edge:https://consultia.travel">Abrir en edge</a> -->
</footer>
