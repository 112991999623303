import { Injectable } from '@angular/core';
import { CanLoad, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Usuario } from 'src/app/modelos/usuario';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CanLoadAutorizacionesGuard implements CanLoad {
  info_user: Usuario = JSON.parse(localStorage.getItem('info_user'));

  constructor(private _routerService: Router) {}

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    if(this.info_user.Autorizador || environment.demo) return true;
    this._routerService.navigate(['/']);
    return false;
  }
}