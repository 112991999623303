import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../servicios/auth.service';
import { LoginService } from 'src/app/servicios/login.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CanActivateGuard implements CanLoad {
  constructor(private authService: AuthService, private loginService: LoginService) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    const url = `/${segments.join('/')}`;
    if (this.authService.isAuthenticated(url)) {
      return true;
    }
    this.loginService.GetLogOut(this.authService.getToken() ? true : null);
    return false;
  }
}
