import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContenedorCampoFormularioComponent } from './contenedor-campo-formulario.component';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [ContenedorCampoFormularioComponent],
  imports: [CommonModule, TooltipModule],
  exports: [ContenedorCampoFormularioComponent]
})
export class ContenedorCampoFormularioModule {}
