import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CampanaAvisosComponent } from './campana-avisos.component';
import { BadgeModule } from 'primeng/badge';


@NgModule({
  declarations: [CampanaAvisosComponent],
  imports: [
    CommonModule,
    BadgeModule
  ],
  exports: [CampanaAvisosComponent]
})
export class CampanaAvisosModule { }
