import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-autorizaciones-viajes',
  templateUrl: './autorizaciones-viajes.component.html',
  styleUrls: ['./autorizaciones-viajes.component.scss']
})
export class AutorizacionesViajesComponent implements OnInit, OnDestroy {


  constructor() {

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

}
