import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as dayjs from 'dayjs';
import { Funciones } from 'src/assets/funcionesComunes';

@Component({
  selector: 'elegir-rango-fechas',
  templateUrl: './elegir-rango-fechas.component.html',
  styleUrls: ['./elegir-rango-fechas.component.scss']
})
export class ElegirRangoFechasComponent implements OnInit {
  maxDateValue: Date = new Date();
  minDateValue: Date = dayjs(this.maxDateValue).add(-2,'year').toDate();
  rangoFechasForm: FormGroup;
  @Input() textoExplicativo:string = "";
  @Output() onRangeSelection: EventEmitter<any>;
  @Output() onLimpiarSeleccion: EventEmitter<any>;
  @Output() onIrAHoy: EventEmitter<any>;

  constructor(private _formService: FormBuilder) {
    this.onRangeSelection = new EventEmitter<any>();
    this.onLimpiarSeleccion = new EventEmitter<any>();
    this.onIrAHoy = new EventEmitter<any>();
    console.log('min: ',this.minDateValue, 'max: ', this.maxDateValue);
  }

  ngOnInit(): void {
    this.initForm();
    this.rangoFechasForm.valueChanges.subscribe((value)=>{
      if(!value.rangoFechas){
        this.onRangeSelection.emit(null);
        return;
      }
      let fechas:any[] = value.rangoFechas;
      const rangoIncompleto = fechas.some((f)=>{return !f});
      if(rangoIncompleto) return;
      const rangoFechas:any[] = this.rangoFechasForm.value.rangoFechas.map((f)=>{
        return Funciones.getDate(f);
      });
      this.onRangeSelection.emit(rangoFechas);

    });
  }

  initForm() {
    this.rangoFechasForm = this._formService.group({
      rangoFechas:[]
  });
    /* this.rangoFechasForm = this._formService.group({
      fechaDesde:[''],
      fechaHasta:['']
  }); */
  }

  
  hndLimpiarClick():void{
    this.onLimpiarSeleccion.emit();
  }

  hndIrAHoyClick():void{
    this.onIrAHoy.emit();
  }


}
