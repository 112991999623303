import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/servicios/auth.service';
import { LoginService } from 'src/app/servicios/login.service';
import { ServicioGeneralService } from 'src/app/servicios/servicio-general.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CanLoadAutoreservaGuard implements CanLoad {
  constructor(
    private authService: AuthService,
    private loginService: LoginService,
    private servicioGeneral: ServicioGeneralService
  ) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    const url = `/${segments.join('/')}`;
    if (this.servicioGeneral.getNavegadorIE()) {
      return false;
    }
    if (this.authService.isAuthenticated(url)) {
      return true;
    }
    this.loginService.GetLogOut(this.authService.getToken() ? true : null);
    return false;
  }
}
