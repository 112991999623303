import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { filter } from 'rxjs/operators';
import { Usuario } from 'src/app/modelos/usuario';
import { GastosViajesService } from 'src/app/servicios/gastos-viajes.service';
import { ServicioGeneralService } from 'src/app/servicios/servicio-general.service';
import { Constantes, Item, ItemGenerico } from './constantes';
import { environment } from 'src/environments/environment';
import { PermisosService } from 'src/app/servicios/permisos.service';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  info_user: Usuario = JSON.parse(localStorage.getItem('info_user'));

  propsSidebar = { mostrar: false, fijada: true };
  permisoPresupuestos: boolean;
  // mostrar = false;
  // fijada = false;

  menuActivo = '';

  itemsMenu = [];

  environment = environment;

  private suscripcion: Subscription;

  public get expandida() {
    return this.propsSidebar.fijada || this.propsSidebar.mostrar;
  }

  constructor(
    private servicioGeneral: ServicioGeneralService,
    private router: Router,
    private permisosService: PermisosService
  ) {
    router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((ruta: any) => {
      this.setMenuActivo(ruta.url);
    });
  }

  ngOnInit(): void {
    this.setItemsMenu();
    this.suscripcion = this.servicioGeneral.propsSidebar$.subscribe((valor) => {
      this.propsSidebar = { ...valor };
    });

    let info_user_admin = JSON.parse(localStorage.getItem('info_user_admin'));

    this.permisoPresupuestos = info_user_admin?.Empresas?.some((element: any) => {
      return element.Presupuestos >= 1;
    });
  }

  setItemsMenu() {
    this.itemsMenu = Constantes.itemsMenu.map((item) => {
      const hijos = item.hijos.map((subitem) => ({ ...subitem, deshabilitado: !this.tienePermisos(subitem) }));
      return { ...item, deshabilitado: !this.tienePermisos(item), hijos };
    });
  }

  ngOnDestroy(): void {
    this.suscripcion.unsubscribe();
  }

  setFijado() {
    this.servicioGeneral.switchFijarSidebar();
  }

  setMenuActivo(url: string) {
    if (url == '/') {
      this.menuActivo = 'dashboard';
    } else if (url.includes('presupuesto')) {
      this.menuActivo = 'travel-manager';
    } else if (url.includes('geoposicion') || url.includes('calendar')) {
      this.menuActivo = 'herramientas';
    } else if (url.includes('alta-usuarios') || url.includes('alta-tarjetas')) {
      this.menuActivo = 'creacion';
    } else if (url.includes('compensacionco2')) {
      this.menuActivo = 'compensacion-co2';
    } else {
      this.menuActivo = url.split('/')[1];
    }
  }

  tienePermisos(item: Item | ItemGenerico): boolean {
    if (item.tipo == 'administracion') {
      const existeHijo = Object.keys(this.info_user.PermisosAdministracion).some((permiso) => {
        return this.info_user.PermisosAdministracion[permiso] == true && permiso != 'SuperUser';
      });
      return existeHijo;
    } else if (item.tipo.includes('-admin')) {
      const tipo = item.tipo.split('-')[0];
      return this.info_user.PermisosAdministracion[tipo];
    } else if (item.tipo == 'herramientas') {
      if (this.info_user.empleados_seleccionables) return true;
      return false;
    } else if (item.tipo == 'creacion') {
      if (this.info_user.PermisosAdministracion.SuperUser) return true;
      return false;
    } else if (item.tipo == 'autorreserva') {
      return this.info_user.PuedeReservar;
    } else if (item.tipo == 'informe-gastos') {
      if (!this.permisosService.permisoInformesGastos()) {
        return false;
      }
    } else if (item.tipo == 'geoposicion') {
      if (this.info_user.CO != 0) return false;
    } else if (item.tipo == 'usuarios') {
      return this.permisosService.permisoAdministracionUsuarios();
    }
    return true;
  }

  trackBy(index: number, item): number {
    return index;
  }
}
