import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import { TooltipModule } from 'primeng/tooltip';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { ToastModule } from 'primeng/toast'
import { AutorizacionesViajesComponent } from './autorizaciones-viajes.component';
import { AutorizacionesViajesRoutingModule } from './autorizaciones-viajes-routing.module';
import { AutorizacionesViajesListadoComponent } from './autorizaciones-viajes-listado/autorizaciones-viajes-listado.component';
import { ElegirAutorizadorDialogModule } from './autorizaciones-viajes-compartido/elegir-autorizador-dialog/elegir-autorizador-dialog.module';
import { ElegirRangoFechasModule } from './autorizaciones-viajes-compartido/elegir-rango-fechas/elegir-rango-fechas.module';
import { PrecioModule } from '../shared/precio/precio.module';


const COMPONENTS = [AutorizacionesViajesComponent,
  AutorizacionesViajesListadoComponent];
@NgModule({
  declarations: [COMPONENTS],
  imports: [
    CommonModule,
    TranslateModule,
    AutorizacionesViajesRoutingModule,
    TableModule,
    TagModule,
    DropdownModule,
    MultiSelectModule,
    FormsModule,
    TooltipModule,
    CalendarModule,
    ToastModule,
    ElegirAutorizadorDialogModule,
    ElegirRangoFechasModule,
    PrecioModule

  ],
  exports: [COMPONENTS]
})
export class AutorizacionesViajesModule { }
