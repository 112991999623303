import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'autoreserva-contenedor-campo-formulario',
  templateUrl: './contenedor-campo-formulario.component.html',
  styleUrls: ['./contenedor-campo-formulario.component.scss']
})
export class ContenedorCampoFormularioComponent implements OnInit {
  @ViewChild('contenedor') contenedor: ElementRef;
  @Input() _id: string;
  @Input() disabled: boolean = false;
  @Input() disabledTooltip: string = '';
  @Input() placeholder = '';
  constructor() {}

  ngOnInit(): void {}

  switchActivo() {
    this.eliminarContenedorActivo();

    if (this.contenedor.nativeElement) {
      this.contenedor.nativeElement.classList.add('contenedor-input--activo');
      const input = this.contenedor.nativeElement.getElementsByClassName(
        'contenedor-input__input'
      )[0] as HTMLInputElement;
      if (input) input.focus();
    }
  }

  private eliminarContenedorActivo() {
    const contenedorActivo = document.getElementsByClassName('contenedor-input--activo');
    if (contenedorActivo.length > 0) contenedorActivo[0].classList.remove('contenedor-input--activo');
  }
}
