import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NavbarComponent } from './navbar.component';
import {CarouselModule} from 'primeng/carousel';
const routes: Routes = [
  {
    path: '',
    component: NavbarComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule, CarouselModule]
})
export class NavbarRoutingModule {}
