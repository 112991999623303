<div class="navbar">
  <div class="icono-menu" (click)="switchMenu()">
    <i class="fas fa-bars"></i>
  </div>
  <span class="identificador-configuracion" *ngIf="environment.production == false">
    <ng-container *ngIf="environment.demo == true">
      DEMO
    </ng-container>
    <ng-container *ngIf="environment.test == true">
      TEST
    </ng-container>
    <ng-container *ngIf="environment.show == true">
      S
    </ng-container>
  </span>
  <ng-container *ngIf="getAlertas() >= 1">
    <!-- <div class="contenedor-marquesina">
      <p-carousel [value]="alertas" [autoplayInterval]="getAlertas()== 1? 0 : 10000" [circular]="true">
        <ng-template let-alerta pTemplate="item">
          <div class="marquesina" [ngStyle]="{color: alerta.color}">
            <div class="d-flex justify-content-center align-items-center marquesina__icono">
              <i class="{{alerta.icono}}" [ngStyle]="{color: alerta.color}" ></i>
            </div>
            <span [innerHtml]="alerta.texto" class="marquesina__texto"></span>
          </div>
        </ng-template>
      </p-carousel>
    </div> -->

    <div class="contenedor-boton-alertas ms-2">
      <button type="button" class="btn btn-danger tarjeta__icono-alertas" (click)="abrirModalAlertas()">
        {{ 'travel-manager.dashboard.ver-alertas' | translate}}
        <span class="badge bg-light text-dark">{{getAlertas()}}</span>
      </button>
    </div>
  </ng-container>


  <!-- <div *ngIf="getAlertas && getMensajeMantenimiento" class="marquesinaVertical">
      <div *ngFor="let alerta of alertas">
        <span [innerHtml]="alerta"></span>
        <hr/>
      </div>

      <div>
        <span>
          {{ 'mantenimientos.mensaje-navbar' | translate: {
          dia: respuestaMantenimiento.mantenimiento?.fechainicio | date: 'dd/MM/yyyy',
          horaini: respuestaMantenimiento.mantenimiento?.fechainicio | date: 'HH:mm',
          horafin: respuestaMantenimiento.mantenimiento?.fechafin | date: 'HH:mm'
          } }}
        </span>
      </div>
    </div> -->

  <!-- <div *ngIf="getMensajeMantenimiento()" id='mensaje-mantenimiento'>
    <p class="cnnContents">
      <span class="marqueeStyle">
        &nbsp;&nbsp;&nbsp;
        {{ 'mantenimientos.mensaje-navbar' | translate: {
        dia: respuestaMantenimiento.mantenimiento?.fechainicio | date: 'dd/MM/yyyy',
        horaini: respuestaMantenimiento.mantenimiento?.fechainicio | date: 'HH:mm',
        horafin: respuestaMantenimiento.mantenimiento?.fechafin | date: 'HH:mm'
        } }}
        &nbsp;&nbsp;&nbsp;
      </span>
      <span class="marqueeStyle2 ">
        &nbsp;&nbsp;&nbsp;
        {{ 'mantenimientos.mensaje-navbar' | translate: {
        dia: respuestaMantenimiento.mantenimiento?.fechainicio | date: 'dd/MM/yyyy',
        horaini: respuestaMantenimiento.mantenimiento?.fechainicio | date: 'HH:mm',
        horafin: respuestaMantenimiento.mantenimiento?.fechafin | date: 'HH:mm'
        } }}
        &nbsp;&nbsp;&nbsp;
      </span>
    </p>
  </div> -->
  <div class="ms-auto">
    <app-banderas [reload]="true" tipo="desplegable"></app-banderas>
  </div>
  <div style="min-width: 12%;">
    <div class="navbar__usuario" [matMenuTriggerFor]="belowMenu">
      <span class="usuario__logo"> {{ usuario.Nombre | slice: 0:1 }} </span>
      <span class="usuario__nombre-completo"> {{ usuario.Nombre }} {{ usuario.Apellidos }} </span>
    </div>
    <mat-menu #belowMenu="matMenu" yPosition="below">
      <button routerLink="/perfil" mat-menu-item routerLinkActive="boton-presionado"
        [disabled]="!usuario.empleados_seleccionables">
        <i class="far fa-address-card"></i><span class="ms-icon">{{ 'navbar.mi-perfil' | translate }}</span>
      </button>
      <button *ngIf="autorizacionesViajesSolicitante?.length > 0" routerLink="/autorizaciones-viajes-enviadas" mat-menu-item routerLinkActive="boton-presionado">
        <i class="fas fa-user-shield"></i><span class="ms-icon">{{ 'navbar.autorizaciones-enviadas' | translate }}</span>
      </button>
      <button *ngIf="esAutorizador" routerLink="/autorizaciones-viajes-recibidas" mat-menu-item routerLinkActive="boton-presionado">
        <i class="fas fa-clipboard-list"></i><span class="ms-icon">{{ 'navbar.autorizaciones-recibidas' | translate
          }}</span>
      </button>
      <button (click)="cerrarSesion()" mat-menu-item>
        <i class="fas fa-sign-out-alt"></i><span class="ms-icon">{{ 'navbar.cerrar-sesion' | translate }}</span>
      </button>
    </mat-menu>
  </div>
  <div>
    <app-boton-ayuda></app-boton-ayuda>
  </div>
</div>
<div class="contenedor-alertas" [style.background-color]="colorAlerta" *ngIf="getAlertas() >= 1">
  <p-carousel [value]="alertas" [autoplayInterval]="getAlertas()== 1? 0 : 10000" [circular]="true"
    (onPage)="actualizarColor($event)">
    <ng-template let-alerta pTemplate="item">
      <div class="marquesina">
        <div class="d-flex justify-content-center align-items-center marquesina__icono">
          <i class="fa {{alerta.icono}}"></i>
        </div>
        <span [innerHtml]="alerta.texto" class="marquesina__texto"></span>
      </div>
    </ng-template>
  </p-carousel>
</div>
