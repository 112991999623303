import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElegirAutorizadorDialogComponent } from './elegir-autorizador-dialog.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MultiSelectModule } from 'primeng/multiselect';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { ContenedorCampoFormularioModule } from 'src/app/autorreserva/autorreserva-compartido/contenedor-campo-formulario/contenedor-campo-formulario.module';

const ENTRY_COMPONENTS = [ElegirAutorizadorDialogComponent]

@NgModule({
  declarations: ENTRY_COMPONENTS,
  imports: [
    CommonModule,
    MultiSelectModule,
    PipesModule,
    FormsModule,
    TranslateModule,
    ContenedorCampoFormularioModule
  ],
  exports:ENTRY_COMPONENTS
})
export class ElegirAutorizadorDialogModule { }
