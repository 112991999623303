<!-- <div>Mi Navegación</div> -->
<ng-container>
  <div [class.layout]="userLoggedIn">
    <app-sidebar *ngIf="userLoggedIn"></app-sidebar>
    <div
      [class.contenedor-principal]="userLoggedIn"
      [class.contenedor-principal--fijado]="(propsSidebar$ | async).fijada && userLoggedIn"
    >
      <app-navbar *ngIf="userLoggedIn" class="animated fadeIn"></app-navbar>
      <div class="contenedor-principal__contenido">
        <div *ngIf="loading && userLoggedIn" class="barra-loading">
          <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
        </div>
        <ngx-spinner bdColor="rgba(0,0,0,0.85)" size="medium" color="#fff" type="ball-spin-fade">
          <h5 style="color: white">{{ 'spinner-global.texto' | translate }}...</h5>
        </ngx-spinner>

        <router-outlet (activate)="onActivate($event)"> </router-outlet>
        <app-scroll-top></app-scroll-top>
      </div>
      <app-footer *ngIf="userLoggedIn"></app-footer>
    </div>
  </div>
</ng-container>
