import { Component, OnInit } from '@angular/core';
import { Usuario } from 'src/app/modelos/usuario';

@Component({
  selector: 'app-boton-ayuda',
  templateUrl: './boton-ayuda.component.html',
  styleUrls: ['./boton-ayuda.component.scss']
})
export class BotonAyudaComponent implements OnInit {
  usuario: Usuario = JSON.parse(localStorage.getItem('info_user'));
  constructor() {}

  ngOnInit(): void {}
}
